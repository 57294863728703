@charset "utf-8"; // section.fc_top

section.fc_top {
    padding: 60px 20px 120px;
    @media #{$size_800} {
        padding: 40px 20px 100px;
    }
    @media #{$size_600} {
        padding: 40px 20px 80px;
    }
    @media #{$size_400} {
        padding: 40px 20px 60px;
    }
}

section.fc_top div.fc_top_titleArea {
    padding-bottom: 38px;
    @media #{$size_800} {
        padding-bottom: 50px;
    }

    i {
        @include fontsize(24);
        display: block;
    }
}

section.fc_top div.fc_top_verticalText {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    position: relative;

    p {
        width: 300px;
        height: 350px;
        position: relative;

        span {
            display: inline-block;
            text-align: center;
            @include YuMincho;
            @include fontsize(42);
            color: #fff;
            background-color: rgba(0, 0, 0, 0.8);
            padding: 20px 2px;
            word-wrap: normal;

            &:nth-child(1) {
                position: absolute;
                top: 0;
                right: 0;
            }
            &:nth-child(2) {
                position: absolute;
                top: 0;
                right: 80px;
            }
            &:nth-child(3) {
                position: absolute;
                top: 0;
                left: 80px;
            }
            &:nth-child(4) {
                position: absolute;
                top: 0;
                left: 0px;
            }
        }
        @include hack(fox) {
            width: 300px;
            span {
                padding: 20px 6px;
                &:nth-child(2) {
                    right: 76px;
                }
                &:nth-child(3) {
                    left: 76px;
                }
            }
        }
        @include hack() {
            span {
                padding: 20px 13px 10px 0px;
                &:nth-child(2) {
                    right: 76px;
                }
                &:nth-child(3) {
                    left: 76px;
                }
            }
        }

        @media #{$size_700} {
            width: 200px;
            span {
                @include fontsize(24);
                &:nth-child(2) {
                    right: 55px;
                }
                &:nth-child(3) {
                    left: 55px;
                }
            }
            @include hack(fox) {
                span {
                    &:nth-child(2) {
                        right: 52px;
                    }
                    &:nth-child(3) {
                        left: 52px;
                    }
                }
            }
            @include hack() {
                padding: 19px 11px 10px 0px;
                span {
                    &:nth-child(2) {
                        right: 55px;
                    }
                    &:nth-child(3) {
                        left: 55px;
                    }
                }
            }
        }

    }

    &::after {
        display: block;
        content: '';
        width: 100%;
        height: 462px;
        background-image: url("../images/fc_top_soba.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center bottom;
        position: absolute;
        top: 184px;
        left: 0;
        z-index: -1;
        @media #{$size_1000} {
            height: 400px;
            top: 140px;
        }
        @media #{$size_800} {
            height: 360px;
            top: 110px;
        }
        @media #{$size_600} {
            height: 280px;
            top: 100px;
        }
        @media #{$size_400} {
            height: 220px;
            top: 100px;
        }
    }
}

.fc_top_main {
    margin-top: 255px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    padding: 40px 50px;
    box-sizing: border-box;
    color: #000;
    h2 {
        @include NotoSansCJKjp-Medium;
        line-height: 1.6;
        padding-bottom: 10px;
    }
    p {
        text-align: left;
        @include NotoSansCJKjp-Regular;
        padding-bottom: 40px;
        line-height: 1.8;
    }
    div {
        max-width: 276px;
        margin-left: auto;
        margin-right: auto;
        img {
            width: 100%;
            height: auto;
        }
    }
    @media #{$size_1000} {
        margin-top: 160px;
    }
    @media #{$size_700} {
        margin-top: 80px;
        padding: 30px 30px;
        p {
            padding-bottom: 30px;
        }
        div {
            max-width: 250px;
        }
    }
    @media #{$size_600} {
        margin-top: 0px;
    }
    @media #{$size_400} {
        margin-top: -60px;
        padding: 20px 10px;
        p {
            padding-bottom: 20px;
        }
        div {
            max-width: 200px;
        }
    }
}



//section.fc_concept

section.fc_concept {
    background-image: url("../images/common/unnamed.jpg");
    background-size: 280px;
    background-repeat: repeat;
    margin: 0 auto;
    padding: 55px 0 165px;
    @media #{$size_700} {
        padding: 30px 0 80px;
    }
}

div.fc_concept_titleArea {
    padding-bottom: 130px;
    @media #{$size_600} {
        padding-bottom: 80px;
    }
}

div.fc_concept_contentsArea {
    margin: 0 30px;
    @media #{$size_1000} {
        margin: 0 20px;
    }

    div.concept_contents {
        max-width: 1510px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: flex-end;
        padding-bottom: 120px;
        flex-direction: row-reverse;
        @media #{$size_900} {
            padding-bottom: 90px;
        }
        @media #{$size_700} {
            display: block;
            padding-bottom: 60px;
        }

        p.fc_detailText {
            margin-left: 16.7549668874%;
            margin-right: 2.3178807947%;
            width: 33.3112582781%;
            text-align: left;
            @include NotoSansCJKjp-Regular;
            @include fontsize(15);
            line-height: 1.8;
            font-weight: normal;
            color: #000;
            @media #{$size_700} {
                margin-left: 0%;
                margin-right: 0%;
                width: 100%;
                @include fontsize(14);
            }
        }

        div.summaryArea {
            width: 47.6158940398%;
            @media #{$size_700} {
                width: 100%;
                padding-bottom: 20px;
            }

            div.imgBox {
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        &:nth-child(even) {
            justify-content: flex-end;
            flex-direction: row;

            p.fc_detailText {
                margin-right: 16.7549668874%;
                margin-left: 2.3178807947%;
            }

            div.summaryArea {
                p.fc_verticalText {
                    left: auto;
                    left: initial;
                    top: -40px;
                    right: 40px;
                    @media #{$size_1000} {
                        left: auto;
                        left: initial;
                        right: 30px;
                    }
                    @media #{$size_600} {
                        left: auto;
                        left: initial;
                        right: 20px;
                    }
                }
            }
        }

        &:last-child {
            padding-bottom: 0;
        }
    }
}

div.summaryArea {
    position: relative;

    p.fc_verticalText {
        position: absolute;
        top: -40px;
        left: 40px;
        width: 106px;
        height: 405px;
        .vertical-writing {
            @include fontsize(32);
        }
        @media #{$size_1400} {
            .vertical-writing {
                @include fontsize(28.8);

            }
        }
        @media #{$size_1200} {
            width: 80px;
            .vertical-writing {
                @include fontsize(20.8);
            }
        }
        @media #{$size_1000} {
            width: 70px;
            left: 30px;
            .vertical-writing {
                @include fontsize(19.2);
            }
        }
        @media #{$size_600} {
            left: 20px;
        }

        span {
            @include YuMincho;
            @include fontsize(22);
            color: #fff;
            display: inline-block;
            background-color: #000;
            text-align: center;
            padding: 15px 2px;
            word-wrap: normal;
            &:nth-child(1) {
                position: absolute;
                top: 0;
                right: 0;

            }
            &:nth-child(2) {
                position: absolute;
                top: 0;
                left: 0;

            }
            @include hack() {
                padding: 15px 7px 15px 0px;
            }
        }
    }
}
//section.fc_support

section.fc_support {
    padding: 260px 20px 106px;
    margin: 0 auto;
    position: relative;
    @media #{$size_1000} {
        padding: 230px 20px 80px;
    }
    @media #{$size_600} {
        padding: 200px 20px 60px;
    }

    &::before {
        display: block;
        content: '';
        background-image: url("../images/fc_support.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center top;
        width: 100%;
        height: 245px;
        position: absolute;
        top: 0;
        left: 0;
        @media #{$size_1000} {
            height: 200px;
        }
        @media #{$size_600} {
            height: 150px;
        }
    }
}

div.fc_support_titleArea {
    padding-bottom: 40px;

    span {
        padding-bottom: 30px;
    }
}

ul.fc_support_typeOfService {
    margin: 0 auto;
    width: 53%;
    display: flex;
    justify-content: center;
    @media #{$size_800} {
        width: auto;
    }

    li {
        background-color: #f5f5f5;
        border: 3px solid #000;
        padding: 35px 30px;
        margin-right: 70px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        @media #{$size_1200} {
            padding: 30px 25px;
        }
        @media #{$size_1000} {
            padding: 25px 20px;
            margin-right: 50px;
        }
        @media #{$size_800} {
            padding: 20px 15px;
            margin-right: 40px;
        }
        @media #{$size_600} {
            padding: 15px 12px;
            margin-right: 25px;
            border: 2px solid #000;
        }
        @media #{$size_400} {
            padding: 15px 10px;
            margin-right: 15px;
            border: 2px solid #000;
        }

        &:last-child {
            margin-right: 0;
        }

        span.vertical-writing {
            @include YuMincho;
            @include fontsize(40);
            line-height: 1.2;
            color: #000;
            writing-mode: tb-rl;
            @media #{$size_1200} {
                @include fontsize(36);
            }
            @media #{$size_1000} {
                @include fontsize(28);
            }
            @media #{$size_800} {
                @include fontsize(24);
            }
            @media #{$size_600} {
                @include fontsize(16);
            }
            @media #{$size_400} {
                @include fontsize(13);
            }
        }
    }
}

ul.fc_support_typeOfService li.mix-writing {
    display: flex;
    flex-direction: column;
    align-items: center;

    span.horizontal-writing {
        @include YuMincho;
        @include fontsize(20);
        font-weight: normal;
        line-height: 2.4;
        color: #000;
        letter-spacing: -0.1em;
        text-align: center;
        @media #{$size_1000} {
            @include fontsize(18);
        }
        @media #{$size_800} {
            @include fontsize(14);
        }
        @media #{$size_600} {
            @include fontsize(10);
            letter-spacing: -0.3em;
        }
        @media #{$size_400} {
            @include fontsize(8);
            letter-spacing: -0.3em;
        }
    }
}
//section.membership

section.fc_membership {
    margin: 0 auto;
    padding: 0 30px 135px;
}

section.fc_membership {
    div.fc_membership_titleArea {
        padding-bottom: 60px;

        span {
            padding-bottom: 30px;
        }
    }
    @media #{$size_700} {
        div.fc_membership_titleArea {
            padding-bottom: 40px;
        }
    }
}

section.fc_membership div.membership-conditions {
    margin: 0 auto;
    max-width: 1000px;
    @media #{$size_1200} {
        max-width: 800px;
    }
    @media #{$size_1000} {
        max-width: 780px;
    }
    @media #{$size_800} {
        max-width: 580px;
    }

    & > dl {
        display: flex;
        padding: 20px 0;
        border-top: 1px solid #000;
        @media #{$size_600} {
            display: block;
        }

        &:last-child {
            border-bottom: 1px solid #000;
        }

        dd,
        dt {
            @include NotoSansCJKjp-Regular;
            @include fontsize(15);
            color: #000;
            font-weight: normal;
            text-align: left;
            line-height: 1.6;
            @media #{$size_1200} {
                @include fontsize(14);
            }
        }

        & > dt {
            width: 16.5%;
            @media #{$size_600} {
                @include fontsize(14);
                font-weight: bold;
                padding-bottom: 15px;
                width: auto;
            }
        }
    }
}

dl.conditions_companyName {
    dd:first-of-type {
        margin-right: 40px;
    }
}

dl.conditions_contractTerm {
    dd:first-of-type {
        margin-right: 18px;
    }
}

dl.conditions_contractDetail {
    div.contractDetail-ddArea {
        width: 78.5%;
        @media #{$size_1200} {
            width: 85%;
        }
        @media #{$size_600} {
            width: auto;
        }

        dd.design-advise {
            padding-bottom: 20px;
        }

        dl.contractDtail_trainingExpense {
            dt {
                width: auto;
                position: relative;
                padding-left: 1rem;

                &::before {
                    display: block;
                    content: '・';
                    position: absolute;
                    left: 0;
                }
            }
        }
    }
}

dl.conditions_operationExpense {
    div.operationExpense-ddArea {
        dd:first-of-type {
            padding-bottom: 20px;
        }
    }
}

dl.conditions_stuffOperation {
    div.stuffOperation-ddArea {
        dd.stuffList {
            padding-bottom: 20px;
        }

        dl.stuff {
            display: flex;

            dt {
                width: auto;
                margin-right: 25px;
            }
        }
    }
}

dl.conditions_sanitaryManagement {
    div.sanitaryManagement-ddArea {
        width: 78.5%;
        @media #{$size_1200} {
            width: 85%;
        }
        @media #{$size_600} {
            width: auto;
        }
    }
}
//section.fc_flow

section.fc_flow {
    padding: 270px 20px 100px;
    margin: 0 auto;
    position: relative;
    background-image: url("../images/common/unnamed.jpg");
    background-size: 280px;
    background-repeat: repeat;
    @media #{$size_1000} {
        padding: 210px 20px 100px;
    }
    @media #{$size_600} {
        padding: 150px 20px 60px;
    }

    &::before {
        display: block;
        content: '';
        background-image: url("../images/fc_flow.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center top;
        width: 100%;
        height: 266px;
        position: absolute;
        top: 0;
        left: 0;
        @media #{$size_1000} {
            height: 200px;
        }
        @media #{$size_600} {
            height: 150px;
        }
    }
}

div.fc_flow_titleArea {
    padding-bottom: 15px;

    span {
        padding-bottom: 30px;
    }
}

section.fc_flow {
    ul.fc_flow_chart {
        margin: 0 auto;
        max-width: 660px;
        @media #{$size_800} {
            max-width: 560px;
        }
        @media #{$size_600} {
            max-width: none;
            width: 80%;
        }
        @media #{$size_600} {
            width: 90%;
        }

        li {
            width: 100%;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            border: 3px solid #000;
            box-sizing: border-box;
            margin-bottom: 62px;
            @media #{$size_800} {
                height: 50px;
                margin-bottom: 52px;
            }
            @media #{$size_400} {
                height: 45px;
                margin-bottom: 47px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            span {
                display: block;
                @include NotoSansCJKjp-Medium;
                @include fontsize(15);
                font-weight: normal;
                line-height: 1.6;
                @media #{$size_600} {
                    @include fontsize(14);
                }
            }
        }
    }
}

section.fc_flow ul.fc_flow_chart {
    span.font_YuMincho-preOpen {
        @include YuMincho;
        @include fontsize(26);
        font-weight: 600;
        @media #{$size_800} {
            @include fontsize(20);
        }
        @media #{$size_600} {
            @include fontsize(16);
        }
    }

    span.font_YuMincho-grandOpen {
        @include YuMincho;
        @include fontsize(30);
        font-weight: 600;
        @media #{$size_800} {
            @include fontsize(24);
        }
        @media #{$size_600} {
            @include fontsize(18);
        }
    }
}

section.fc_flow ul.fc_flow_chart li {
    position: relative;

    &::after {
        display: block;
        content: '';
        background-image: url("../images/fc_flow_arrow.png");
        background-size: contain;
        background-repeat: no-repeat;
        width: 15px;
        height: 35px;
        position: absolute;
        bottom: -50px;
        left: 0;
        right: 0;
        margin: auto;
        @media #{$size_600} {
            width: 10px;
            height: 24px;
            bottom: -40px;
        }
    }

    &:last-child {
        &::after {
            display: none;
        }
    }
}

section.fc_flow ul.fc_flow_chart li {
    span.font_YuMincho-grandOpen {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;

        &::before {
            display: block;
            content: '';
            width: 8px;
            height: 25px;
            background-color: transparent;
            border-right: 3px solid #000;
            border-left: 3px solid #000;
            margin-right: 25px;
            transform: skew(30deg);
            @media #{$size_800} {
                width: 8px;
                height: 20px;
            }
            @media #{$size_600} {
                width: 6px;
                height: 18px;
            }
        }

        &::after {
            display: block;
            content: '';
            width: 8px;
            height: 25px;
            background-color: transparent;
            border-right: 3px solid #000;
            border-left: 3px solid #000;
            margin-left: 25px;
            transform: skew(-30deg);
            @media #{$size_800} {
                width: 8px;
                height: 20px;
            }
            @media #{$size_600} {
                width: 6px;
                height: 18px;
            }
        }
    }
}
//aside.link_to_inquiry

aside.link_to_inquiry {
    padding: 65px 20px 185px;
    padding: 65px 20px;
    margin: 0 auto;
    @media #{$size_600} {
        padding: 80px 20px;
        padding: 45px 20px;
    }
    a {
        margin: 0 auto;
        max-width: 660px;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border: 3px solid #000;
        @media #{$size_800} {
            max-width: 560px;
            height: 120px;
        }
        @media #{$size_600} {
            max-width: none;
            width: 80%;
        }
        @media #{$size_600} {
            width: 90%;
        }
        p {
            @include NotoSansCJKjp-Medium;
            @include fontsize(20);
            font-weight: normal;
            line-height: 1.8;
            letter-spacing: 0.03em;
            @media #{$size_800} {
                @include fontsize(18);
            }
            @media #{$size_600} {
                @include fontsize(16);
                letter-spacing: 0;
                line-height: 1.6;
            }
        }
    }
}

.fc_model {
    background-image: url("../images/common/unnamed.jpg");
    background-size: 280px;
    background-repeat: repeat;
    margin: 0 auto;
    .fc_model_title {
        padding-bottom: 50px;
    }
    .wrap {
        overflow: hidden;
        .main {
            display: flex;
            padding-bottom: 50px;
            article {
                background-color: #fff;
                border: 3px solid #000;
                box-sizing: border-box;
                width: 32%;
                margin-right: 2%;
                padding-top: 28px;
                padding-bottom: 22px;
                padding-left: 1.8%;
                padding-right: 1.8%;
                color: #000;
                &:last-child {
                    margin-right: 0%;
                }
                h3 {
                    font-weight: 500;
                    @include YuMincho;
                    text-align: center;
                    padding-bottom: 18px;
                }
                p {
                    @include NotoSansCJKjp-Medium;
                    text-align: left;
                    line-height: 1.8;
                    padding-bottom: 25px;
                    padding-left: 2%;
                    padding-right: 2%;
                }
                div {
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }

    @media #{$size_700} {
        .fc_model_title {
            padding-bottom: 40px;
        }
        .wrap {
            .main {
                display: block;
                article {
                    border: 2px solid #000;
                    width: 100%;
                    margin-right: 0% !important;
                    padding-top: 20px;
                    padding-bottom: 15px;
                    padding-left: 20px;
                    padding-right: 20px;
                    margin-bottom: 30px;
                    &:last-child {
                        margin-bottom: 0%;
                    }
                    h3 {
                        padding-bottom: 15px;
                    }
                    p {
                        padding-bottom: 20px;
                        padding-left: 0%;
                        padding-right: 0%;
                    }
                }
            }
        }
    }
}


.fc_model {
    .wrap {
        .main2 {
            padding-bottom: 170px;
            position: relative;
            width: 100%;
            min-height: 380px;
            display: flex;
            flex-direction: row-reverse;
            .right {
                max-width: 780px;

            }
            .left {
                position: relative;
                z-index: 20;
                width: 70%;
                padding-top: 35px;
                box-sizing: border-box;
                .fc_slider {
                    border: 3px solid #000;
                    box-sizing: border-box;
                    .fc_slider_item {
                        position: relative;
                        span {
                            position: absolute;
                            top: 0;
                            -ms-writing-mode: tb-rl;
                            -webkit-writing-mode: vertical-rl;
                            writing-mode: vertical-rl;
                            @include gothic1;
                            @include fontsize(16);
                            display: inline-block;
                            align-self: flex-start;
                            padding: 10px 10px;
                            word-wrap: normal;
                            font-weight: 500;
                            &:nth-child(1) {
                                right: 92px;
                                background-color: #fff;
                                color: #000;
                            }
                            &:nth-child(2) {
                                right: 46px;
                                background-color: #000;
                                color: #fff;
                            }
                        }
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }

                }
                .fc_slider_nav {
                    width: 130px;
                    margin-left: auto;
                    padding-left: 16px;
                    padding-right: 16px;
                    padding-top: 15px;
                    padding-bottom: 15px;
                    box-sizing: border-box;
                    background-color: #000;
                    transform:translateX(-62px);
                    .fc_slider_nav_item {
                        outline: none;
                        @include NotoSansCJKjp-Medium;
                        color: #fff;
                        cursor: pointer;
                    }
                    .slick-arrow {
                        width: 60px;
                        height: 100%;
                        background-color: #000;
                        background-size: 11px;
                        background-position: center;
                        background-repeat: no-repeat;
                        &::before {
                            display: none;
                        }
                    }
                    .slick-prev {
                        background-image: url("../images/fc/prev.png");
                        left: -62px;

                    }
                    .slick-next {
                        background-image: url("../images/fc/next.png");
                        right: -62px;
                    }
                }
            }
        }
    }
    @media #{$size_900} {
        .wrap {
            .main2 {
                .right {
                    max-width: 680px;
                }
            }
        }
    }
    @media #{$size_700} {
        .wrap {
            .main2 {
                padding-bottom: 60px;
                min-height: 200px;
                display: block;
                .right {
                    transform: translateX(-6px);
                    margin-top: 40px;
                    width: 90px;
                    margin-left: auto;
                    margin-right: auto;
                    position: static;
                    left: auto;
                    left: initial;
                }
                .left {
                    width: 100%;
                    padding-top: 0px;
                    margin-top: -20px;
                    .fc_slider {
                        border: 2px solid #000;
                    }
                    .fc_slider_nav {
                        padding-left: 8px;
                        padding-right: 8px;
                        padding-top: 8px;
                        padding-bottom: 8px;
                        transform:translateX(-42px);
                        .slick-arrow {
                            width: 40px;
                            background-size: 6px;
                        }
                        .slick-prev {
                            left: -42px;

                        }
                        .slick-next {
                            right: -42px;
                        }
                    }
                }
            }
        }
    }
    @media #{$size_400} {
        .wrap {
            .main2 {
                .left {
                    .fc_slider {
                        .fc_slider_item {
                            span {
                                padding: 8px 8px;
                                @include fontsize(14);
                                &:nth-child(1) {
                                    right: 66px;
                                }
                                &:nth-child(2) {
                                    right: 26px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.fc_max1000 {
    width: 100%;
    text-align: left;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

section.fc_flow ul.fc_flow_chart .fc_grandopen {
    padding-top: 12px;
    padding-bottom: 8px;
    height: auto;
    display: block;
    color: #000 !important;
    span {
        @include fontsize(30);
        display: inline-block;
        padding-bottom: 6px;
        @include YuMincho;
        font-weight: 600;
        position: relative;
        &::before {
            position: absolute;
            top: 7px;
            left: -25px;
            display: block;
            content: '';
            width: 8px;
            height: 25px;
            background-color: transparent;
            border-right: 2px solid #000;
            border-left: 2px solid #000;
            transform: skew(30deg);
        }
        &::after {
            position: absolute;
            top: 7px;
            right: -25px;
            display: block;
            content: '';
            width: 8px;
            height: 25px;
            background-color: transparent;
            border-right: 2px solid #000;
            border-left: 2px solid #000;
            transform: skew(-30deg);
        }
    }
    p {
        @include NotoSansCJKjp-Medium;
        padding-left: 10px;
        padding-right: 10px;
    }
    @media #{$size_700} {
        span {
            @include fontsize(26);
            &::before {
                position: absolute;
                top: 7px;
                left: -20px;
                display: block;
                content: '';
                width: 5px;
                height: 23px;
            }
            &::after {
                position: absolute;
                top: 7px;
                right: -20px;
                display: block;
                content: '';
                width: 5px;
                height: 23px;
            }
        }
    }
    @media #{$size_400} {
        span {
            @include fontsize(23);
            &::before {
                position: absolute;
                top: 5px;
                left: -18px;
                display: block;
                content: '';
            }
            &::after {
                position: absolute;
                top: 5px;
                right: -18px;
                display: block;
                content: '';
            }
        }
    }
}

.fc_700_width100 {
    @media #{$size_700} {
        width: 100% !important;
    }
}

.fc_title_ul {
    max-width: 880px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    display: flex;
    li {
        background-image: url("../images/common/unnamed.jpg");
        background-size: 280px;
        background-repeat: repeat;
        width: 14%;
        margin-right: 7.5%;
        &:last-child {
            margin-right: 0%;
        }
        border: 3px solid #000;
        box-sizing: border-box;
        span {
            padding: 40px 25.641025641% 20px 25.641025641%;
            display: block;
            img {
                width: 100%;
                height: auto;
            }
        }
    }
    @media #{$size_700} {
        li {
            border: 2px solid #000;
            span {
                padding: 16px 25.641025641% 8px 25.641025641%;
            }
        }
    }
}

.overflow_hidden {
    overflow: hidden;
}
