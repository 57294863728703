@charset "UTF-8";
// @import url('https://fonts.googleapis.com/css?family=Lora');
//セッティング
@import "base/setting";
@import "base/reset";

// plugins
@import "plugins/slick";
@import "plugins/slick-theme";


//パーツ
@import "base/parts";

//animation setting
@import "base/animeSetting";

//各テンプレートパーツの装飾
@import "layout/header";
@import "layout/footer";
@import "layout/top";
@import "layout/fc";
@import "layout/contact";
@import "layout/news";
@import "layout/shop";
@import "layout/quality";
@import "layout/menu";
