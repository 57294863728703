@charset "utf-8";


.top_box1 {
    .main {
        .top_box1_slick_li {
            a {
                display: block;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
        .slick-prev, .slick-next {
            z-index: 40;
            width: 35px;
            height: 35px;
            &::before {
                display: none;
            }
            &::after {
                display: block;
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
            }
        }
        .slick-prev {
            left: 200px;
            // left: 165px;
            &::after {
                background-image: url("../images/top/prev.png");
            }
        }
        .slick-next {
            right: 200px;
            // right: 165px;
            &::after {
                background-image: url("../images/top/next.png");
            }
        }
    }

    .news {
        background-color: #fff;
        border-top: 1px solid #c6af8d;
        box-sizing: border-box;
        .wrap {
            display: flex;
            h2 {
                display: flex;
                padding-top: 15px;
                padding-bottom: 15px;
                margin-right: 26px;
                div {
                    width: 49px;
                    margin-right: 20px;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                span {
                    padding-top: 10px;
                    box-sizing: border-box;
                    color: #000;
                    display: inline-block;
                    @include NotoSansCJKjp-Medium;
                }
            }
            article {
                text-align: left;
                padding-top: 10px;
                padding-bottom: 10px;
                a {
                    display: flex;
                    padding-top: 22px;
                    padding-bottom: 22px;
                    border-left: 2px solid #000;
                    padding-left: 30px;

                    .day {
                        color: #666666;
                        @include YuMincho;
                        margin-right: 35px;
                    }
                    h3 {
                        @include transition02;
                        color: #000;
                        @include NotoSansCJKjp-Regular;

                    }
                    &:hover {
                        h3 {
                            @include transition02;
                            text-decoration: underline;
                        }

                    }
                }
            }
        }
    }

    @media #{$size_1300} {
        .main {
            .slick-prev {
                left: 165px;
            }
            .slick-next {
                right: 165px;
            }
        }
    }
    @media #{$size_1200} {
        .main {
            .slick-prev {
                left: 65px;
            }
            .slick-next {
                right: 65px;
            }
        }
    }
    @media #{$size_700} {
        .main {
            .slick-prev {
                left: 15px;
            }
            .slick-next {
                right: 15px;
            }
        }
        .news {
            padding-bottom: 20px;
            .wrap {
                display: block;
                h2 {
                    padding-top: 24px;
                    padding-bottom: 5px;
                    margin-right: 0px;
                    div {
                        width: 44px;
                        margin-right: 18px;
                    }
                    span {
                        padding-top: 14px;
                    }
                }
                article {
                    padding-top: 0px;
                    padding-bottom: 0px;
                    a {
                        padding-top: 0px;
                        padding-bottom: 0px;
                        border-left: none;
                        padding-left: 0px;

                        .day {
                            width: 80px;
                            margin-right: 20px;
                            line-height: 1.8;
                        }
                        h3 {
                            line-height: 1.6;
                            flex: 1;
                            color: #000;
                            @include NotoSansCJKjp-Regular;
                            position: relative;
                        }
                    }
                }
            }
        }
    }
    @media #{$size_600} {
        .main {
            .slick-prev, .slick-next {
                width: 20px;
                height: 20px;
            }
            .slick-prev {
                left: 5px;
            }
            .slick-next {
                right: 5px;
            }
        }
    }
    @media #{$size_400} {
        .news {
            .wrap {
                article {
                    a {
                        .day {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
}

.top_box2 {
    background-image: url("../images/top/bk1.png");
    background-repeat: repeat-x;
    background-size: 35px;
    background-position: 0 0;
    padding-top: 145px;
    padding-bottom: 268px;
    position: relative;
    .item1 {
        position: absolute;
        top: 115px;
        left: 0;
        max-width: 732px;
        width: 45.75%;
        img {
            width: 100%;
            height: auto;
        }
    }

    &::after {
        content: '';
        display: block;
        background-image: url("../images/top_appeal_textBackimg.png");
        background-size: contain;
        background-repeat: no-repeat;
        width: 681px;
        height: 831px;
        opacity: 0.15;
        z-index: -1;
        position: absolute;
        top: 0;
        right: 0;
    }

    .wrap {
        text-align: left;
        .text1 {
            position: relative;
            z-index: 10;
            display: flex;
            justify-content: flex-end;
            article {
                width: 50%;
                h2 {
                    @include YuMincho;
                    color: #000;
                    line-height: 1.4;
                    letter-spacing: -0.05em;
                    text-align: left;
                    font-weight: bold;
                    padding-bottom: 20px;
                }
                p {
                    line-height: 1.8;
                    letter-spacing: -1px;
                    @include YuMincho;
                    color: #000;
                    font-weight: bold;
                    padding-bottom: 16px;
                }
                .top_box2_div1 {
                    @include NotoSansCJKjp-Regular;
                    font-weight: normal;
                    line-height: 1.8;
                    letter-spacing: -1px;
                    text-align: left;
                    padding-bottom: 60px;
                }
            }
        }
    }
    @media #{$size_1300} {
        .item1 {
            position: absolute;
            left: 0;
            max-width: 732px;
            width: 45.75%;
        }
    }

    @media #{$size_1000} {
        &::after {
            width: 600px;
            height: 733px;
        }
        .wrap {
            .text1 {
                article {
                    width: 60%;
                    h2 {
                        @include fontsize(56);
                        letter-spacing: -2px;
                    }
                }
            }
        }
        .item1 {
            width: 42%;
        }
    }
    @media #{$size_700} {
        padding-top: 60px;
        padding-bottom: 100px;
        .item1 {
            position: static;
            max-width: 400px;
            width: 100%;
            padding-bottom: 15px;
        }
        .wrap {
            .text1 {
                display: block;
                article {
                    width: 100%;
                    h2 {
                        @include fontsize(48);
                        letter-spacing: -1px;
                        padding-bottom: 15px;
                    }
                    p {
                        padding-bottom: 10px;
                    }
                    .top_box2_div1 {
                        padding-bottom: 30px;
                    }
                }
            }
        }

    }

    @media #{$size_400} {
        &::after {
            width: 400px;
            height: 489px;
        }
        .item1 {
            max-width: 280px;
        }
        .wrap {
            .text1 {
                article {
                    h2 {
                        @include fontsize(40);
                    }
                    .top_box2_div2 {
                        .linkBox {
                            display: block;
                            max-width: 260px;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }
}

// section.slider
section.slider {
    display: flex;
    justify-content: center;
    align-items: center;

    div.slide_wrapper {
        width: 33.33%;

        ul.slideList {
            li.slideItem {
                position: relative;

                div {
                    width: 100%;

                    img {
                        display: block;
                        width: 100%;
                    }
                }

                p.slide_text {
                    -ms-writing-mode: tb-rl;
                    writing-mode: vertical-rl;
                    @include YuMincho-36p-Kana;
                    @include fontsize(86);
                    font-weight: normal;
                    color: #000;
                    text-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
                    display: flex;
                    justify-content: center;
                    position: absolute;
                    top: 50px;
                    right: 35%;
                    @media #{$size_1300} {
                        @include fontsize(76);
                    }
                    @media #{$size_1200} {
                        @include fontsize(70);
                    }
                    @media #{$size_1100} {
                        @include fontsize(64);
                    }
                    @media #{$size_1000} {
                        top: 40px;
                        @include fontsize(58);
                    }
                    @media #{$size_900} {
                        top: 30px;
                        @include fontsize(52);
                    }
                    @media #{$size_800} {
                        @include fontsize(48);
                    }
                    @media #{$size_700} {
                        @include fontsize(40);
                    }
                    @media #{$size_600} {
                        top: 20px;
                        @include fontsize(32);
                    }
                    @media #{$size_500} {
                        @include fontsize(24);
                    }
                    @media #{$size_400} {
                        top: 15px;
                        @include fontsize(18);
                    }
                }
            }
        }
    }
}
//section.appeal
section.appeal {
    margin: 0 auto;
    max-width: 1600px;
    height: 830px;
    padding-bottom: 170px;
    @media #{$size_1300} {
        height: 770px;
    }
    @media #{$size_1200} {
        height: 760px;
    }
    @media #{$size_1000} {
        overflow: hidden;
    }
    @media #{$size_600} {
        padding-bottom: 190px;
    }
    @media #{$size_400} {
        padding-bottom: 220px;
    }
}

section.appeal {
    position: relative;

    dl.appeal_menu {
        position: absolute;
        top: 4%;
        left: 17%;
        @media #{$size_1300} {
            top: 7%;
            left: 13%;
        }
        @media #{$size_1100} {
            top: 10%;
            left: 10%;
        }
        @media #{$size_1000} {
            top: 8%;
            left: 10%;
        }
        @media #{$size_800} {
            top: 0;
            left: 5%;
        }
        @media #{$size_600} {
            top: 0;
            left: 0;
        }
    }

    div.zarusoba-img {
        width: 44%;
        position: absolute;
        top: -11%;
        right: 3%;
        @media #{$size_1400} {
            width: 40%;
            top: -7%;
        }
        @media #{$size_1100} {
            top: -5%;
        }
        @media #{$size_1000} {
            top: 0;
        }
        @media #{$size_800} {
            right: 2%;
        }
        @media #{$size_600} {
            right: 2%;
            top: 1%;
        }
    }

    div.kakesoba-img {
        width: 41%;
        position: absolute;
        bottom: 170px;
        left: 0;
        @media #{$size_1000} {
            bottom: 370px;
        }
        @media #{$size_800} {
            bottom: 430px;
        }
        @media #{$size_700} {
            bottom: 540px;
        }
        @media #{$size_600} {
            bottom: 560px;
        }
        @media #{$size_500} {
            bottom: 650px;
        }
        @media #{$size_400} {
            bottom: 730px;
        }
    }

    div.textarea {
        position: absolute;
        bottom: 100px;
        left: 48%;
        @media #{$size_1400} {
            left: 48%;
        }
        @media #{$size_1100} {
            left: 43%;
        }
        @media #{$size_1000} {
            position: relative;
            bottom: 0;
            left: 0;
        }
    }

    &::after {
        content: '';
        display: block;
        background-image: url("../images/top_appeal_textBackimg.png");
        background-size: contain;
        background-repeat: no-repeat;
        width: 47%;
        height: 100%;
        opacity: 0.15;
        z-index: -1;
        position: absolute;
        top: 0;
        right: 0;
        @media #{$size_1400} {
            width: 42%;
        }
        @media #{$size_800} {
            width: 56%;
        }
        @media #{$size_600} {
            width: 60%;
        }
    }
}

section.appeal {
    dl.appeal_menu {
        // max-width: 510px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        z-index: 10;
        @media #{$size_800} {
            padding-top: 50px;
            max-width: initial;
            width: 57%;
            justify-content: center;
        }
        @media #{$size_400} {
            width: 57%;
        }

        div.menu_name {
            padding-right: 15px;
            @media #{$size_600} {
                padding-right: 10px;
            }
            @media #{$size_400} {
                padding-right: 5px;
            }

            dt {
                @include YuMincho-36p-Kana;
                @include fontsize(30);
                font-weight: normal;
                color: #000;
                line-height: 1.26;
                letter-spacing: -0.1em;
                @media #{$size_1200} {
                    @include fontsize(28);
                }
                @media #{$size_1000} {
                    @include fontsize(26);
                }
                @media #{$size_800} {
                    @include fontsize(20);
                }
                @media #{$size_600} {
                    @include fontsize(18);
                }
                @media #{$size_500} {
                    @include fontsize(14);
                }
                @media #{$size_400} {
                    @include fontsize(12);
                }
            }
        }

        div.menu_price {
            display: flex;
            // justify-content: center;
            align-items: center;
            flex-direction: row;

            dd {
                @include Num;
                @include fontsize(86);
                color: #e80000;
                display: block;
                @media #{$size_1200} {
                    @include fontsize(84);
                }
                @media #{$size_1000} {
                    @include fontsize(70);
                }
                @media #{$size_900} {
                    @include fontsize(58);
                }
                @media #{$size_800} {
                    @include fontsize(48);
                }
                @media #{$size_600} {
                    @include fontsize(38);
                }
                @media #{$size_500} {
                    @include fontsize(28);
                }
                @media #{$size_400} {
                    @include fontsize(22);
                }
            }

            b.currency_yen {
                @include fontsize(58);
                font-weight: normal;
                color: #e80000;
                @media #{$size_1000} {
                    @include fontsize(50);
                }
                @media #{$size_900} {
                    @include fontsize(40);
                }
                @media #{$size_800} {
                    @include fontsize(28);
                }
                @media #{$size_600} {
                    @include fontsize(24);
                }
                @media #{$size_500} {
                    @include fontsize(16);
                }
                @media #{$size_400} {
                    @include fontsize(14);
                }
            }

            b.tax-separate {
                @include fontsize(24);
                font-weight: normal;
                color: #e80000;
                vertical-align: -3px;
                @media #{$size_1000} {
                    @include fontsize(22);
                }
                @media #{$size_900} {
                    @include fontsize(18);
                }
                @media #{$size_800} {
                    @include fontsize(14);
                }
                @media #{$size_500} {
                    @include fontsize(12);
                }
                @media #{$size_400} {
                    @include fontsize(10);
                }
            }
        }
    }
}

section.appeal {
    div.imgBox {
        img {
            display: block;
            width: 100%;
        }
    }
}

section.appeal {
    div.textarea {
        max-width: 520px;
        @media #{$size_1000} {
            max-width: 750px;
            padding: 350px 30px 0;
            margin: 0 auto;
        }
        @media #{$size_800} {
            max-width: 540px;
            padding: 350px 30px 0 120px;
            margin: 0 auto;
        }
        @media #{$size_700} {
            max-width: 540px;
            padding: 390px 30px 0;
            margin: 0 auto;
        }
        @media #{$size_600} {
            max-width: 540px;
            padding: 350px 30px 0;
            margin: 0 auto;
        }
        @media #{$size_500} {
            max-width: 540px;
            padding: 320px 30px 0;
            margin: 0 auto;
        }
        @media #{$size_400} {
            max-width: 540px;
            padding: 280px 20px 0;
            margin: 0 auto;
        }

        h2.textarea_title {
            @include NotoSansCJKjp-Regular;
            @include fontsize(44);
            color: #000;
            line-height: 1.6;
            letter-spacing: -0.05em;
            text-align: left;
            @media #{$size_1100} {
                @include fontsize(40);
            }
            @media #{$size_1000} {
                text-align: right;
                padding-bottom: 20px;
            }
            @media #{$size_800} {
                @include fontsize(36);
                text-align: right;
                padding-bottom: 10px;
            }
            @media #{$size_700} {
                @include fontsize(30);
                text-align: center;
                padding-bottom: 10px;
            }
            @media #{$size_400} {
                @include fontsize(24);
            }
        }

        p.appeal_point {
            @include NotoSansCJKjp-Regular;
            @include fontsize(28);
            color: #000;
            line-height: 1.6;
            text-align: left;
            @media #{$size_1100} {
                @include fontsize(24);
            }
            @media #{$size_1000} {
                text-align: right;
                line-height: 2;
            }
            @media #{$size_800} {
                text-align: right;
                line-height: 1.6;
                @include fontsize(20);
            }
            @media #{$size_700} {
                text-align: center;
                line-height: 1.6;
                @include fontsize(18);
            }
            @media #{$size_400} {
                @include fontsize(16);
            }

            b.font_Num {
                @include fontsize(32);
                @media #{$size_1100} {
                    @include fontsize(26);
                }
                @media #{$size_1000} {
                    @include fontsize(24);
                }
                @media #{$size_800} {
                    @include fontsize(22);
                }
                @media #{$size_700} {
                    @include fontsize(20);
                }
                @media #{$size_400} {
                    @include fontsize(18);
                }
            }

            b.fontsize_smaller {
                font-weight: normal;
                @include fontsize(22);
                @media #{$size_1100} {
                    @include fontsize(20);
                }
                @media #{$size_1000} {
                    @include fontsize(18);
                }
                @media #{$size_800} {
                    @include fontsize(16);
                }
                @media #{$size_700} {
                    @include fontsize(14);
                }
                @media #{$size_400} {
                    @include fontsize(12);
                }
            }
        }

        p.appeal_detail {
            @include NotoSansCJKjp-Regular;
            @include fontsize(15);
            font-weight: normal;
            line-height: 1.8;
            letter-spacing: -0.05em;
            text-align: left;
            padding: 20px 0 50px;
            @media #{$size_1000} {
                line-height: 2;
                letter-spacing: 0;
                padding: 30px 0 50px;
            }
            @media #{$size_400} {
                padding: 30px 0 60px;
            }
        }
    }
}
//section.menuIntro
section.menuIntro {
    background-image: url("../images/top_menuIntro_bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 45px 0;
    @media #{$size_800} {
        padding: 50px 0 100px;
    }
    @media #{$size_600} {
        padding: 50px 0 160px;
    }
    @media #{$size_400} {
        padding: 50px 0 120px;
    }

    h2 {
        @include YuMincho;
        @include fontsize(60);
        font-weight: normal;
        color: #000;
        line-height: 1.5;
        padding-bottom: 30px;
        @media #{$size_1200} {
            @include fontsize(50);
        }
        @media #{$size_1000} {
            @include fontsize(42);
        }
        @media #{$size_800} {
            @include fontsize(36);
            padding-bottom: 20px;
        }
        @media #{$size_600} {
            @include fontsize(30);
        }
    }

    p.menuIntro_subheading {
        @include YuMincho;
        font-weight: 600;
        @include fontsize(28);
        color: #000;
        line-height: 1.3;
        letter-spacing: -0.05em;
        padding-bottom: 15px;
        text-align: center;
        @media #{$size_1200} {
            @include fontsize(24);
        }
        @media #{$size_800} {
            padding-left: 60px;
            padding-bottom: 20px;
            @include fontsize(22);
        }
        @media #{$size_600} {
            padding-left: 0;
            text-align: center;
            line-height: 1.8;
            @include fontsize(20);
        }
        @media #{$size_400} {
            @include fontsize(18);
        }
    }

    p.menuIntro_text {
        @include NotoSansCJKjp-Regular;
        @include fontsize(15);
        color: #000;
        line-height: 1.8;
        padding-bottom: 50px;
        text-align: left;
        @media #{$size_600} {
            padding-bottom: 40px;
        }
        @media #{$size_400} {
            padding-bottom: 30px;
            @include fontsize(14);
        }
    }
}

a.menuIntro_linkBox {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: 70px;
    box-sizing: border-box;
    text-align: center;
    position: relative;
    padding: 10px 30px;
}

section.menuIntro {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    div.menuIntro_contents-innerFrame {
        max-width: 570px;
        margin: 0 auto;
        @media #{$size_1200} {
            max-width: 420px;
        }
        @media #{$size_800} {
            max-width: 540px;
        }
        @media #{$size_600} {
            max-width: initial;
            width: 85%;
        }
        @media #{$size_400} {
            max-width: initial;
            width: auto;
            padding: 0 20px;
        }
    }

    &::before {
        display: block;
        content: '';
        width: 26%;
        height: 100%;
        background-image: url("../images/top_menuIntro_kakesoba.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left bottom;
        position: absolute;
        left: 0;
        bottom: 0;
    }

    &::after {
        display: block;
        content: '';
        width: 25%;
        height: 100%;
        background-image: url("../images/top_menuIntro_zarusoba.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right bottom;
        position: absolute;
        right: 0;
        bottom: 0;
    }
}
// section.imgLinks
section.imgLinks {
    padding-bottom: 130px;
}

section.imgLinks {
    div.imgLinks_slider {
        margin-bottom: 120px;
        @media #{$size_1000} {
            margin-bottom: 0;
        }
        @media #{$size_800} {
            margin-bottom: 10;
        }
        @media #{$size_600} {
            margin-bottom: 0;
        }

        ul.imgLinks_slideList {
            li.slideItem {
                outline : none;
                display: block;
                // width: 240px;
                height: 240px;
                padding: 0 5px;
                margin: 0 auto;

                div {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }
}

section.imgLinks div.imgLinkArea {
    display: flex;
    justify-content: center;
    @media #{$size_900} {
        display: block;
        padding: 0 30px;
    }
    @media #{$size_900} {
        padding: 0 20px;
    }

    a.imgLinkBox {
        display: block;
        width: 480px;
        height: 350px;
        position: relative;
        @media #{$size_1200} {
            width: 400px;
            height: 290px;
        }
        @media #{$size_900} {
            width: 480px;
            height: 350px;
            margin: 0 auto;
        }
        @media #{$size_600} {
            width: 280px;
            height: 204px;
        }

        &::before {
            display: block;
            content: '';
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-size: cover;
            background-repeat: no-repeat;
            z-index: -2;
            @include transition0-3;
        }
        &::after {
            display: block;
            content: '';
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-size: cover;
            background-repeat: no-repeat;
            z-index: -1;
            @include transition0-3;
        }
    }

    a.imgLinkBox_shopInfo {
        margin-right: 45px;
        @media #{$size_1200} {
            margin-right: 3%;
        }
        @media #{$size_900} {
            margin: 0 auto 50px;
        }

        &::before {
            background-image: url("../images/top_imgLinks_imgLinkArea_shopInfo-BG.jpg");
        }

    }

    a.imgLinkBox_FC {
        border: 3px solid #000;
        box-sizing: border-box;
        &::before {
            background-image: url("../images/top_imgLinks_imgLinkArea_FC-BG.jpg");
        }
    }
}

section.imgLinks div.imgLinkArea a.imgLinkBox_shopInfo {
    div.imgLinkBox_contents {
        padding: 105px 0 85px;
        margin: 0 auto;
        @media #{$size_1200} {
            padding: 60px 0 0;
        }
        @media #{$size_900} {
            padding: 105px 0 85px;
        }
        @media #{$size_900} {
            padding: 60px 0 0;
        }
        @media #{$size_600} {
            padding: 50px 0 0;
        }

        h3 {
            @include transition02;
            @include YuMincho-36p-Kana;
            @include fontsize(60);
            font-weight: normal;
            color: #000;
            @media #{$size_1200} {
                @include fontsize(50);
            }
            @media #{$size_900} {
                @include fontsize(60);
            }
            @media #{$size_600} {
                @include fontsize(30);
            }
        }

        span {
            @include transition02;
            display: block;
            @include YuMincho-36p-Kana;
            @include fontsize(22);
            font-weight: normal;
            color: #000;
            line-height: 2;
            padding-bottom: 25px;
            letter-spacing: 1px;
            @media #{$size_600} {
                @include fontsize(18);
                padding-bottom: 10px;
            }
        }

        p {
            @include transition02;
            @include YuMincho;
            @include fontsize(16);
            font-weight: normal;
            color: #000;
        }
    }
    &:hover {
        &::after {
            @include transition02;
            background: #000;
        }
        div.imgLinkBox_contents {
            h3 {
                @include transition02;
                color: #fff;
            }

            span {
                @include transition02;
                color: #fff;
            }

            p {
                @include transition02;
                color: #fff;
            }
        }
    }
}

section.imgLinks div.imgLinkArea a.imgLinkBox_FC {
    div.imgLinkBox_contents {
        width: 100%;
        height: 100%;
        position: relative;

        div.imgLinkBox_contents_title {
            @include transition02;
            background-color: #000;
            box-sizing: border-box;
            position: relative;
            &::before {
                @include transition02;
                content: "";
                display: block;
                width: 1px;
                height: 2px;
                background-color: transparent;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
            }

            h3 {
                @include transition02;
                @include YuMincho-36p-Kana;
                @include fontsize(60);
                font-weight: normal;
                color: #fff;
                padding-top: 20px;
                line-height: 1.2;
                @media #{$size_1200} {
                    padding-top: 10px;
                    @include fontsize(36);
                }
                @media #{$size_900} {
                    padding-top: 20px;
                    @include fontsize(60);
                }
                @media #{$size_600} {
                    padding-top: 10px;
                    @include fontsize(22);
                }
            }

            span {
                @include transition02;
                display: block;
                @include YuMincho-36p-Kana;
                @include fontsize(22);
                font-weight: normal;
                color: #fff;
                line-height: 2;
                @media #{$size_1200} {
                    @include fontsize(18);
                    line-height: 1.8;
                }
                @media #{$size_900} {
                    @include fontsize(22);
                    line-height: 2;
                }
                @media #{$size_600} {
                    @include fontsize(14);
                    line-height: 1.8;
                }
            }
        }

        div.imgLinkBox_contents_textArea {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            .top_writing1 {
                top: 43%;
                left: 56px;
                height: 125px;
            }
            .top_writing2 {
                top: 43%;
                left: 20px;
                height: 180px;
            }
            p {
                position: absolute;
                @include transition02;
                writing-mode: vertical-lr;
                display: inline-block;
                font-family: 'メイリオ',Meiryo,'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro',sans-serif;
                @include fontsize(14);
                font-weight: normal;
                color: #fff;
                line-height: 2;
                background-color: #000;
                text-align: left;
                letter-spacing: 1px;
                padding-top: 5px;
            }
        }
    }

    &:hover {
        div.imgLinkBox_contents {
            div.imgLinkBox_contents_title {
                @include transition02;
                background-color: #fff;
                &::before {
                    width: 100%;
                    background-color: #000;
                    @include transition02;
                }
                h3 {
                    @include transition02;
                    color: #000;
                }

                span {
                    @include transition02;
                    color: #000;
                }
            }

            div.imgLinkBox_contents_textArea {
                p {
                    @include transition02;
                    color: #000;
                    background-color: #fff;
                }
            }
        }
    }

    @media #{$size_1200} {
        div.imgLinkBox_contents {
            div.imgLinkBox_contents_textArea {
                .top_writing1 {
                    top: 34%;
                    left: 56px;
                }
                .top_writing2 {
                    top: 34%;
                    left: 20px;
                }
            }
        }
    }
    @media #{$size_900} {
        div.imgLinkBox_contents {
            div.imgLinkBox_contents_textArea {
                .top_writing1 {
                    top: 43%;
                }
                .top_writing2 {
                    top: 43%;
                }
            }
        }
    }
    @media #{$size_600} {
        div.imgLinkBox_contents {
            div.imgLinkBox_contents_textArea {
                .top_writing1 {
                    top: 16%;
                    left: 40px;
                    @include fontsize(12);
                    height: 112px;
                }
                .top_writing2 {
                    top: 16%;
                    left: 10px;
                    @include fontsize(12);
                    height: 158px;
                }
            }
        }
    }
}
//section.topicNews
section.topicNews {
    background-image: url("../images/top_topicNews_BG.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 60px 20px 40px;
    margin-bottom: 200px;
    @media #{$size_1000} {
        padding: 80px 20px 60px;
        margin-bottom: 150px;
    }
    @media #{$size_800} {
        margin-bottom: 100px;
    }
    @media #{$size_800} {
        margin-bottom: 80px;
    }
    @media #{$size_600} {
        margin-bottom: 60px;
    }
}

section.topicNews div.topicNews_titleArea {
    padding-bottom: 50px;
    margin: 0 auto;
    @media #{$size_600} {
        padding-bottom: 40px;
    }

    h2 {
        @include YuMincho-36p-Kana;
        @include fontsize(44);
        font-weight: normal;
        color: #000;
        padding-bottom: 15px;
        @media #{$size_1000} {
            @include fontsize(38);
        }
        @media #{$size_800} {
            @include fontsize(32);
        }
        @media #{$size_600} {
            @include fontsize(30);
        }
        @media #{$size_400} {
            @include fontsize(28);
        }
    }

    span {
        @include YuMincho-36p-Kana;
        @include fontsize(22);
        font-weight: normal;
        color: #000;
        @media #{$size_1000} {
            @include fontsize(20);
        }
        @media #{$size_800} {
            @include fontsize(18);
        }
        @media #{$size_600} {
            @include fontsize(16);
        }
    }
}

section.topicNews div.topicNewsArea {
    max-width: 1000px;
    padding-bottom: 65px;
    margin: 0 auto;
    @media #{$size_1000} {
        max-width: 800px;
    }
    @media #{$size_800} {
        max-width: 600px;
    }
    @media #{$size_600} {
        max-width: initial;
        width: 85%;
    }
    @media #{$size_400} {
        max-width: initial;
        width: 90%;
    }
    a {
        display: block;
        &:hover {
            opacity: 0.6;
        }
    }

    dl.topicNews_list {
        display: flex;
        border-bottom: 1px dotted #999;
        padding: 20px 0;
        @media #{$size_800} {
            display: block;
            text-align: left;
            padding: 15px 0 5px;
        }
        @media #{$size_600} {
            padding: 20px 0 5px;
        }

        dt {
            @include YuMincho;
            @include fontsize(16);
            font-weight: normal;
            color: #666;
            font-style: italic;
            padding-right: 30px;
            @media #{$size_800} {
                @include fontsize(14);
                padding-right: 0;
                padding-bottom: 2px;
            }
            @media #{$size_600} {
                padding-bottom: 5px;
            }
        }

        dd {
            @include NotoSansCJKjp-Regular;
            @include fontsize(15);
            font-weight: normal;
            color: #000;
            @media #{$size_600} {
                @include fontsize(14);
            }
        }
    }
}

section.topicNews a.topicNewArea_link {
    margin: 0 auto;
    display: block;
    width: 252px;
    height: 50px;
    border: 2px solid #000;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        @include NotoSansCJKjp-Regular;
        @include fontsize(15);
        font-weight: normal;
        color: #000;
        letter-spacing: 2px;
        position: relative;
        padding-right: 20px;

        &::after {
            @include transition02;
            display: block;
            content: '';
            width: 27px;
            height: 8px;
            background-image: url("../images/top_topicNews_linkArrow.png");
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            right: -30px;
            bottom: 8px;
        }
    }
    &:hover {
        span {
            &::after {
                @include transition02;
                right: -40px;
            }
        }
    }
}

section.topicNews,
section.topicNews[data-emergence=hidden] {
    position: relative;

    &::before {
        display: block;
        content: '';
        background-image: url("../images/top_topicNews_prawn.png");
        background-size: contain;
        background-repeat: no-repeat;
        width: 35%;
        height: 280px;
        position: absolute;
        top: -12%;
        left: 0;
        transform: rotate(-6deg) scale(1.2);
        transform-origin: left bottom;
        overflow: hidden;
        @media #{$size_1000} {
            width: 40%;
            height: 200px;
        }
        @media #{$size_800} {
            top: -18%;
            width: 50%;
            height: 200px;
        }
        @media #{$size_600} {
            width: 60%;
            height: 200px;
        }
        @media #{$size_500} {
            top: -8%;
        }
        @media #{$size_400} {
            width: 75%;
            top: -12%;
        }
    }
}

section.topicNews[data-emergence=visible] {
    &::before {
        animation: prawnDown 1s forwards;
    }
}

section.topicNews {
    position: relative;

    div.decoTxtArea {
        position: absolute;
        top: 0;
        left: 25%;
        @media #{$size_1200} {
            top: -20px;
            left: 24%;
        }
        @media #{$size_800} {
            top: -40px;
            left: 30%;
        }
        @media #{$size_600} {
            top: -40px;
            left: 40%;
        }
        @media #{$size_500} {
            top: -40px;
            left: 38%;
        }
        @media #{$size_400} {
            top: -27px;
            left: 17%;
        }
    }
}

section.topicNews {
    div#warped {
        position: relative;
        display: block;
        width: 184px;
        height: 94px;

        & > span[class^=w]:nth-of-type(n+0) {
            display: block;
            position: absolute;
            transform-origin: 50% 100%;
        }

        span {
            @include YuMincho-36p-Kana;
            @include fontsize(14);
            color: #000;
            font-weight: normal;
            font-style: normal;
            line-height: 5;
            white-space: pre;
            overflow: visible;
            padding: 0;
        }
    }
}
// section.topicNews 海老の「プリッと新鮮なお知らせ」
section.topicNews {
    #warped .w0 {
        transform: rotate(-6.3rad);
        width: 14px;
        height: 19px;
        left: 9.42px;
        top: 62.99px;
        @include fontsize(14);
    }

    #warped .w1 {
        transform: rotate(-6.37rad);
        width: 14px;
        height: 19px;
        left: 23.28px;
        top: 62.31px;
    }

    #warped .w2 {
        transform: rotate(-6.42rad);
        width: 14px;
        height: 19px;
        left: 36.99px;
        top: 60.84px;
    }

    #warped .w3 {
        transform: rotate(-6.47rad);
        width: 14px;
        height: 19px;
        left: 50.81px;
        top: 58.64px;
    }

    #warped .w4 {
        transform: rotate(-6.52rad);
        width: 14px;
        height: 19px;
        left: 64.56px;
        top: 55.77px;
    }

    #warped .w5 {
        transform: rotate(-6.56rad);
        width: 14px;
        height: 19px;
        left: 78.08px;
        top: 52.3px;
    }

    #warped .w6 {
        transform: rotate(-6.61rad);
        width: 14px;
        height: 19px;
        left: 91.6px;
        top: 48.16px;
    }

    #warped .w7 {
        transform: rotate(-6.66rad);
        width: 14px;
        height: 19px;
        left: 104.89px;
        top: 43.39px;
    }

    #warped .w8 {
        transform: rotate(-6.72rad);
        width: 14px;
        height: 19px;
        left: 117.67px;
        top: 38.02px;
    }

    #warped .w9 {
        transform: rotate(-6.79rad);
        width: 14px;
        height: 19px;
        left: 130.27px;
        top: 31.75px;
    }

    #warped .w10 {
        transform: rotate(-6.89rad);
        width: 14px;
        height: 19px;
        left: 142.02px;
        top: 24.61px;
    }

    #warped .w11 {
        transform: rotate(-7.06rad);
        width: 14px;
        height: 19px;
        left: 153.05px;
        top: 15.75px;
    }
}
/*/
//////////////////////////////////////
animation
///////////////////////////////////////*/
// section.topicNews 海老の「プリッと新鮮なお知らせ」animation
// 「プ」

span.w0[data-emergence=hidden] {
    transform: rotate(-6.3rad) scale(1) translateY(0);
    width: 14px;
    height: 19px;
    left: 9.42px;
    top: 62.99px;
}

span.w0[data-emergence=visible] {
    transform: rotate(-6.3rad) scale(1) translateY(0);
    width: 14px;
    height: 19px;
    left: 9.42px;
    top: 62.99px;
    animation: pu 0.8s 0.3s ease-in-out forwards;
}
// 「り」
span.w1[data-emergence=hidden] {
    transform: rotate(-6.37rad) scale(1) translateY(0);
    width: 14px;
    height: 19px;
    left: 23.28px;
    top: 62.31px;
}

span.w1[data-emergence=visible] {
    transform: rotate(-6.37rad) scale(1) translateY(0);
    width: 14px;
    height: 19px;
    left: 23.28px;
    top: 62.31px;
    animation: ri 0.8s 0.4s ease-in-out forwards;
}
// 「ッ」
span.w2[data-emergence=hidden] {
    transform: rotate(-6.42rad) scale(1) translateY(0);
    width: 14px;
    height: 19px;
    left: 36.99px;
    top: 60.84px;
}

span.w2[data-emergence=visible] {
    transform: rotate(-6.42rad) scale(1) translateY(0);
    width: 14px;
    height: 19px;
    left: 36.99px;
    top: 60.84px;
    animation: ttu 0.8s 0.5s ease-in-out forwards;
}
//  other-to
span.w3[data-emergence=hidden] {
    transform: rotate(-6.47rad) scale(1) translateY(0);
    width: 14px;
    height: 19px;
    left: 50.81px;
    top: 58.64px;
}

span.w3[data-emergence=visible] {
    transform: rotate(-6.47rad) scale(1) translateY(0);
    width: 14px;
    height: 19px;
    left: 50.81px;
    top: 58.64px;
    animation: other-to 0.8s 0.51s ease-in-out forwards;
}
//  other-新
span.w4[data-emergence=hidden] {
    transform: rotate(-6.52rad) scale(1) translateY(0);
    width: 14px;
    height: 19px;
    left: 64.56px;
    top: 55.77px;
}

span.w4[data-emergence=visible] {
    transform: rotate(-6.52rad) scale(1) translateY(0);
    width: 14px;
    height: 19px;
    left: 64.56px;
    top: 55.77px;
    animation: other-shin 0.5s 0.8s ease-in-out forwards;
}
//  other-鮮
span.w5[data-emergence=hidden] {
    transform: rotate(-6.56rad) scale(1) translateY(0);
    width: 14px;
    height: 19px;
    left: 78.08px;
    top: 52.3px;
}

span.w5[data-emergence=visible] {
    transform: rotate(-6.56rad) scale(1) translateY(0);
    width: 14px;
    height: 19px;
    left: 78.08px;
    top: 52.3px;
    animation: other-sen 0.5s 0.8s ease-in-out forwards;
}
//  other-な
span.w6[data-emergence=hidden] {
    transform: rotate(-6.61rad) scale(1) translateY(0);
    width: 14px;
    height: 19px;
    left: 91.6px;
    top: 48.16px;
}

span.w6[data-emergence=visible] {
    transform: rotate(-6.61rad) scale(1) translateY(0);
    width: 14px;
    height: 19px;
    left: 91.6px;
    top: 48.16px;
    animation: other-na 0.5s 0.8s ease-in-out forwards;
}
//  other-お
span.w7[data-emergence=hidden] {
    transform: rotate(-6.66rad) scale(1) translateY(0);
    width: 14px;
    height: 19px;
    left: 104.89px;
    top: 43.39px;
}

span.w7[data-emergence=visible] {
    transform: rotate(-6.66rad) scale(1) translateY(0);
    width: 14px;
    height: 19px;
    left: 104.89px;
    top: 43.39px;
    animation: other-o 0.5s 1s ease-in-out forwards;
}
//  other-し
span.w8[data-emergence=hidden] {
    transform: rotate(-6.72rad) scale(1) translateY(0);
    width: 14px;
    height: 19px;
    left: 117.67px;
    top: 38.02px;
}

span.w8[data-emergence=visible] {
    transform: rotate(-6.72rad) scale(1) translateY(0);
    width: 14px;
    height: 19px;
    left: 117.67px;
    top: 38.02px;
    animation: other-shi 0.5s 1s ease-in-out forwards;
}
//  other-ら
span.w9[data-emergence=hidden] {
    transform: rotate(-6.79rad) scale(1) translateY(0);
    width: 14px;
    height: 19px;
    left: 130.27px;
    top: 31.75px;
}

span.w9[data-emergence=visible] {
    transform: rotate(-6.79rad) scale(1) translateY(0);
    width: 14px;
    height: 19px;
    left: 130.27px;
    top: 31.75px;
    animation: other-ra 0.5s 1s ease-in-out forwards;
}
//  other-せ
span.w10[data-emergence=hidden] {
    transform: rotate(-6.89rad) scale(1) translateY(0);
    width: 14px;
    height: 19px;
    left: 142.02px;
    top: 24.61px;
}

span.w10[data-emergence=visible] {
    transform: rotate(-6.89rad) scale(1) translateY(0);
    width: 14px;
    height: 19px;
    left: 142.02px;
    top: 24.61px;
    animation: other-se 0.5s 1s ease-in-out forwards;
}
//  other-!
span.w11[data-emergence=hidden] {
    transform: rotate(-7.06rad) scale(1) translateY(0);
    width: 14px;
    height: 19px;
    left: 153.05px;
    top: 15.75px;
}

span.w11[data-emergence=visible] {
    transform: rotate(-7.06rad) scale(1) translateY(0);
    width: 14px;
    height: 19px;
    left: 153.05px;
    top: 15.75px;
    animation: other-end 0.5s 1s ease-in-out forwards;
}
//section.appeal の　animation
//section.appeal appeal_menu

section.appeal {
    dl.appeal_menu {
        div.menu_name {
            dt.dt-zarusoba,
            dt.dt-zarusoba[data-emergence=hidden] {
                overflow: hidden;
                opacity: 0;
                transform: translateY(50px);
            }

            dt.dt-kakesoba,
            dt.dt-kakesoba[data-emergence=hidden] {
                overflow: hidden;
                opacity: 0;
                transform: translateY(50px);
            }
        }

        div.menu_price,
        div.menu_price[data-emergence=hidden] {
            opacity: 0;
            transform: translateY(-80px) scale(1.5);
        }
    }
}

section.appeal {
    dl.appeal_menu {
        div.menu_name {
            dt.dt-zarusoba[data-emergence=visible] {
                overflow: hidden;
                opacity: 0;
                transform: translateY(50px);
                animation: fadeIn_fromBtm 0.5s 1 forwards;
            }

            dt.dt-kakesoba[data-emergence=visible] {
                overflow: hidden;
                opacity: 0;
                transform: translateY(50px);
                animation: fadeIn_fromBtm 0.6s 0.8s 1 forwards;
            }
        }

        div.menu_price[data-emergence=visible] {
            opacity: 0;
            transform: translateY(-80px) scale(1.5);
            animation: fadeIn_downSize 0.6s 1.5s 1 forwards, bounce 1s 2.1s 1 forwards;
        }
    }
}

section.appeal,
section.appeal[data-emergence=hidden] {
    &::after {
        transform-origin: right top;
        transform: scale(0);
    }
}

section.appeal[data-emergence=visible] {
    &::after {
        transform-origin: right top;
        transform: scale(0);
        animation: fadeIn_fromTopRight 0.6s 2s 1 forwards;
    }
}
//section.appeal div.zarusoba-img
section.appeal {
    div.zarusoba-img,
    div.zarusoba-img[data-emergence=hidden] {
        opacity: 0;
        transform: translateX(80px) scale(1.2);
        overflow: hidden;
    }
}

section.appeal {
    div.zarusoba-img[data-emergence=visible] {
        opacity: 0;
        transform: translateX(80px) scale(1.2);
        overflow: hidden;
        animation: fadeIn_fromRight 0.5s 1 forwards;
    }
}
//section.appeal div.kakesoba-img
section.appeal {
    div.kakesoba-img,
    div.kakesoba-img[data-emergence=hidden] {
        opacity: 0;
        transform: translateX(-80px) scale(1.2);
        overflow: hidden;
    }
}

section.appeal {
    div.kakesoba-img[data-emergence=visible] {
        opacity: 0;
        transform: translateX(-80px) scale(1.2);
        overflow: hidden;
        animation: fadeIn_fromLeft 0.6s 0.8s 1 forwards;
    }
}
//section.appeal div.textarea .textarea_title
section.appeal div.textarea .textarea_title,
section.appeal div.textarea .textarea_title[data-emergence=hidden] {
    opacity: 0;
}

section.appeal div.textarea .textarea_title[data-emergence=visible] {
    opacity: 0;
    animation: opacity0-1 1.2s 2.6s 1 forwards;
}

section.appeal div.textarea {
    p.appeal_point-01,
    p.appeal_point-01[data-emergence=hidden] {
        opacity: 0;
        transform: translateY(50px);
    }

    p.appeal_point-02,
    p.appeal_point-02[data-emergence=hidden] {
        opacity: 0;
        transform: translateY(50px);
    }

    p.appeal_detail,
    p.appeal_detail[data-emergence=hidden] {
        opacity: 0;
    }
}

section.appeal div.textarea {
    p.appeal_point-01[data-emergence=visible] {
        opacity: 0;
        transform: translateY(50px);
        animation: fadeIn_fromBtm 1.2s 3s 1 forwards;
    }

    p.appeal_point-02[data-emergence=visible] {
        opacity: 0;
        transform: translateY(50px);
        animation: fadeIn_fromBtm 1.2s 3s 1 forwards;
    }

    p.appeal_detail[data-emergence=visible] {
        opacity: 0;
        animation: opacity0-1 1.5s 3.2s 1 forwards;
    }
}
//section.menuIntro の animation
section.menuIntro,
section.menuIntro[data-emergence=hidden] {
    overflow: hidden;

    &::before {
        overflow: hidden;
        opacity: 0;
        transform: translateX(-80px);
    }

    &::after {
        overflow: hidden;
        opacity: 0;
        transform: translateX(80px);
    }
}

section.menuIntro[data-emergence=visible] {
    overflow: hidden;

    &::before {
        opacity: 1;
        overflow: hidden;
        transform: translateX(0px);
        animation: translateX_-80-0 1s forwards;
    }

    &::after {
        opacity: 1;
        overflow: hidden;
        transform: translateX(0px);
        animation: translateX_80-0 1s forwards;
    }
}