@charset "utf-8";

.vertical-writing span.alphanumeric {
    text-orientation: upright;
    -ms-text-orientation: upright;
    -webkit-text-orientation: upright;
    text-align: center;
    display: inline-block;
    /*-webkit-text-combine: horizontal;*/
}

body.menu section.fc_top {
    padding: 0;
    .fc_top_titleArea{
      padding: 60px 20px 60px;
      @media #{$size_800} {
        padding: 40px 20px 60px;
      }
      .cont_section-preface{
        @include NotoSansCJKjp-Regular;
        padding-top: 60px;
        @media #{$size_800} {
          padding-top: 30px;
        }
      }
    }
}
/*html{
	font-family: 'NotoSansCJKjp-Regular', sans-serif;
}
*/
.ancNav {
    max-width: 1040px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    margin: 40px auto 0;
    display: flex;
    box-sizing: border-box;
    // justify-content: center;
    justify-content: space-around;

    li {
        text-align: center;
        // margin: 0 60px;
        p {
            img {
                height: 64px;
            }
        }

        .txt {
            br {
                display: none;
            }
            display: block;
            @include YuMincho-36p-Kana;
            @include fontsize(20);
            margin-top: 10px;
        }
    }

    @media #{$size_700} {
        li {
            p {
                img {
                    height: 60px;
                }
            }

            .txt {
                br {
                    display: block;
                }
                @include fontsize(18);
            }
        }
    }
    @media #{$size_400} {
        li {
            p {
                img {
                    height: 40px;
                }
            }

            .txt {
                @include fontsize(14);
            }
        }
    }
}
/* ++++++++++ そば ++++++++++ */
#anc01 {
    padding-top: 65px;
    @media #{$size_1000} {
        padding-top: 65px;
    }
    @media #{$size_700} {
      .wrap{
        .item1{
          height: 620px;
        }
      }
    }
}

.menu_soba {
    border: 3px solid #a67f48;
    max-width: 1000px;
    margin: 0 auto;
    background: url("/assets/images/common/unnamed.jpg") repeat;
    background-size: 280px;
    box-sizing: border-box;
}

.menu_soba_titleArea {
    float: right;
    width: 300px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-right: 40px;
    text-align: left;
    margin-top: 50px;

    h2 {
        @include YuMincho-36p-Kana;
        @include fontsize(86);
        width: 1em;
        padding-left: 30px;
    }

    p {
        text-orientation: upright;
        text-align: left;
        line-height: 1.7647;
        @include YuMincho-36p-Kana;
        @include fontsize(17);
    }
}

.soba_item {
    h3 {
        display: inline-block;
        width: 164px;
        height: 164px;
        border-radius: 100%;
        line-height: 164px;
        font-size: 60px;
        @include YuMincho-36p-Kana;
        @include fontsize(60);
        font-weight: bold;
        color: #fff;
        background: rgba(0,0,0,0.8);
    }

    .menuImg {
        img {
            width: 100%;
        }
    }

    li {
        p.img {
            img {
                width: 100%;
            }
        }

        p {
            .name {
                @include YuMincho-36p-Kana;
                vertical-align: bottom;
                line-height: 1;
            }

            .price {
                color: #e80000;
                vertical-align: text-bottom;
                line-height: 1;

                em {
                    padding-top: 3px;
                }
            }
        }
    }
}
/* ざる */
.soba_item.zaru {
    position: relative;

    h3 {
        position: absolute;
        left: 43%;
        top: 44%;
    }

    .menuImg {
        max-width: 683px;
        margin-top: -10px;
        margin-left: -83px;
    }

    ul {
        display: flex;
        max-width: 930px;
        margin: 0 auto 74px;

        li {
            width: 33.333333%;
            padding: 0 10px;

            p.img {
                margin-bottom: 2px;
            }

            p {
                .name {
                    @include fontsize(30);
                    font-weight: bold;
                    margin-right: 5px;
                }

                .price {
                    em {
                        @include fontsize(36);
                        font-weight: bold;

                        .yen {
                            @include fontsize(24);
                        }
                    }

                    .tax {
                        @include fontsize(16);
                    }
                }
            }
        }
    }
}
/* 特盛 */
.soba_item.tokumori {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    flex-direction: row-reverse;
    padding: 0 80px 0 100px;
    position: relative;

    h3 {
        position: absolute;
        top: 0;
        left: 65%;
    }

    .catchphrase {
        text-orientation: upright;
        text-align: left;
        width: 120px;

        span {
            display: inline-block;
            margin-right: 7px;
            background: #000;
            color: #fff;
            line-height: 32px;
            padding: 10px 0;
            text-align: left;
            @include fontsize(20);
        }
    }

    p.priceBox {
        text-align: left;

        .name {
            display: block;
            margin-right: 5px;
            @include YuMincho-36p-Kana;
            @include fontsize(40);
            font-weight: bold;
            line-height: 1;
        }

        .price {
            display: block;
            color: #e80000;

            em {
                @include fontsize(46);
                font-weight: bold;

                .yen {
                    @include fontsize(30);
                }
            }

            .tax {
                @include fontsize(17);
            }
        }
    }

    p.img {
        max-width: 466px;
        margin: 0 auto;

        img {
            width: 100%;
        }
    }
}
/* かけ */
.soba_item.kake {
    margin-bottom: 70px;
    position: relative;

    h3 {
        position: absolute;
        top: 148px;
        left: 50%;
    }

    .menuImg {
        width: 640px;
        margin-left: -72px;
    }

    ul {
        display: flex;
        margin-top: -92px;
        margin-left: 400px;

        li {
            max-width: 264px;
            margin: 0 4px;

            p.img {
                width: 100%;

                img {
                    width: 100%;
                }
            }

            p {
                .name {
                    @include fontsize(30);
                    font-weight: 500;
                    margin-right: 5px;
                }

                .price {
                    em {
                        @include fontsize(36);
                        font-weight: bold;

                        .yen {
                            @include fontsize(24);
                        }
                    }

                    .tax {
                        @include fontsize(16);
                    }
                }
            }
        }
    }
}

/* 肉つけそば　 */
.soba_item.nikutsuke {
    display: flex;
    padding-bottom: 22px;
    position: relative;

    h3 {
        background: #000;
        border-radius: 0;
        display: inline-block;
        line-height: 1;
        @include fontsize(60);
        padding: 0 25px;
        position: relative;
        z-index: 1;
        order: 1;
        margin-right: 64px;
        margin-top: 28px;
        width: 111px;
        height: 345px;
        box-sizing: border-box;
        margin-left: -2px;

        em {
            background: url("../images/menu/nikutsuke_bg.png") no-repeat;
            position: absolute;
            @include fontsize(28);
            letter-spacing: 10px;
            padding: 15px 10px 0;
            top: -11px;
            right: -35px;
            transform: rotate(6deg);
        }
    }

    p.img {
        width: 825px;

        img {
            width: 100%;
        }
    }

    ul.priceBox {
        position: absolute;
        bottom: 118px;
        right: 80px;
        width: 177px;
        height: 231px;
        height: 341px;
        z-index: 2;

        li {
            background: #e80000;
            border-radius: 100%;
            color: #fff;
            width: 127px;
            height: 127px;
            @include fontsize(20);
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
            order: 2;
            position: absolute;
            text-align: center;

            P {
                /*display: flex;
                align-items: center;
                flex-wrap: wrap;
                justify-content:flex-start;*/
                text-align: left;
                padding-left: 8px;
                letter-spacing: -1px;

                .name {
                    @include NotoSansCJKjp-Regular;
                    width: 100%;
                    padding-bottom: 8px;
                    text-align: left;
                    display: inline-block;
                }

                .price {
                    display: inline-block;
                    position: relative;
                    width: 100%;
                    line-height: 1;
                    vertical-align: bottom;
                    text-align: left;

                    em {
                        @include fontsize(43);
                        color: #fff;
                        font-weight: bold;

                        .yen {
                            @include fontsize(26);
                        }
                    }

                    .tax {
                        @include fontsize(12);
                        color: #fff;
                        position: absolute;
                        top: -2px;
                        right: 10px;
                        letter-spacing: 0;
                    }
                }
            }
            &.price01 {
                left: 0;
                top: 0;
                order: 1;
            }
            &.price02 {
                right: 0;
                bottom: 0;
                order: 2;
            }
            &.price03 {
                left: 0;
                bottom: 0;
                order: 3;
            }
        }
    }
}
/* トッピングメニュー */
#anc02 {
    padding-top: 95px;
}

.menu_topping {
    padding-bottom: 20px;

    .menu_topping_titleArea {
        h2 {
            @include YuMincho-36p-Kana;
            @include fontsize(66);
            line-height: calc(80 / 66);
            @media #{$size_1200} {
                margin-bottom: 5px;
                @include fontsize(48);
            }
            @media #{$size_800} {
                @include fontsize(40);
                margin-bottom: 10px;
            }
            @media #{$size_600} {
                @include fontsize(34);
                margin: 0 20px 10px;
            }
            @media #{$size_400} {
                @include fontsize(28);
            }
        }

        .subTitle {
            @include fontsize(15);
            @include NotoSansCJKjp-Regular;
            line-height: calc(27 / 15);
            @media #{$size_1000} {
                padding: 0 15px;
            }
            @media #{$size_800} {
                text-align: left;
                margin: 0 5px;
            }
            @media #{$size_500} {
                text-align: left;
                @include fontsize(14);
            }
        }
    }

    .toppingList {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        margin: 0 auto;
        max-width: 1040px;
        padding-top: 60px;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        // @media #{$size_800} {
        //     padding: 40px 10px 0;
        // }
        // @media #{$size_600} {
        //     padding: 25px 10px 0;
        // }

        li {
            // width: 33.333333333%;
            width: 32.4%;
            margin-right: 1.4%;
            &:nth-child(3n) {
                margin-right: 0%;
            }
            // @media #{$size_800} {
            //     width: 50%;
            // }
            // @media #{$size_500} {
            //     width: 100%;
            // }
            // @media #{$size_400} {
            //     margin: 0 20px;
            // }
            @media #{$size_700} {
                width: 49%;
                margin-right: 2%;
                &:nth-child(3n) {
                    margin-right: 2%;
                }
                &:nth-child(2n) {
                    margin-right: 0%;
                }
            }
            @media #{$size_500} {
                width: 100%;
                margin-right: 0% !important;
            }
            div {
                box-sizing: border-box;
                // margin: 0 18px;
                padding-bottom: 50px;
                @media #{$size_800} {
                    // margin: 0 12px;
                    padding-bottom: 50px;
                }
                @media #{$size_500} {
                    // margin: 0 auto;
                    padding-bottom: 30px;
                }

                .toppingImg {
                    box-sizing: border-box;
                    border: 3px solid #a67f48;
                    background: #fff;
                    margin-bottom: 30px;
                    @media #{$size_800} {
                        margin-bottom: 20px;
                    }
                    @media #{$size_500} {
                        max-width: 360px;
                        margin: 0 auto 15px;
                    }

                    img {
                        width: 100%;
                    }
                }

                .name {
                    @include fontsize(36);
                    @include YuMincho-36p-Kana;
                    font-weight: 500;
                    line-height: 1;
                    @media #{$size_1000} {
                        @include fontsize(30);
                    }
                    @media #{$size_600} {
                        @include fontsize(26);
                    }

                    span {
                        @include fontsize(24);
                        @media #{$size_1000} {
                            @include fontsize(20);
                        }
                        @media #{$size_600} {
                            display: block;
                            margin-top: 3px;
                            @include fontsize(18);
                        }
                        @media #{$size_500} {
                            display: inline;
                            margin-top: 0;
                        }
                    }
                }

                .price {
                    @include fontsize(18);
                    @include NotoSansCJKjp-Regular;
                    font-weight: 500;
                    margin-top: 10px;
                    @media #{$size_1000} {
                        @include fontsize(17);
                        margin-top: 3px;
                    }

                    .num {
                        margin-right: 5px;
                    }

                    em {
                        color: #e80000;
                    }
                }

                .catchphrase {
                    @include fontsize(15);
                    font-weight: bold;
                    margin-top: -15px;
                    margin-bottom: 10px;
                    @media #{$size_600} {
                        @include fontsize(13);
                        margin-top: -10px;
                        margin-bottom: 5px;
                    }
                }

                .comment {
                    @include fontsize(14);
                    @include NotoSansCJKjp-Regular;
                    margin-top: 15px;
                    margin-bottom: -20px;
                    text-align: left;
                    @media #{$size_1000} {
                        margin-top: 12px;
                    }
                    @media #{$size_600} {
                        @include fontsize(13);
                    }
                }
            }
        }
    }

    .att {
        @include fontsize(15);
        @include NotoSansCJKjp-Regular;
        text-align: center;
        line-height: calc(27 / 15);

        p {
            margin-left: 1em;

            &::before {
                content: '※';
                display: inline-block;
                margin-left: -1em;
            }
        }
        @media #{$size_1000} {
            text-align: left;
            margin: 0 15px;
        }
    }
}
/* 17時以降の限定メニュー 丼とそばのセット */
#anc03 {
    margin-top: -63px;
    padding-top: 63px;
}

.menu_set {
    background: url("../images/menu/set_bg.png") repeat;
    padding: 57px 0 75px;

    .menu_set_titleArea {
        color: #fff;
        position: relative;
        max-width: 930px;
        margin: 0 auto 80px;

        @media #{$size_500} {
            margin: 0 auto 50px;
        }

        h2 {
            @include YuMincho-36p-Kana;
            @include fontsize(66);
            line-height: 1;
            margin-bottom: 20px;
            @media #{$size_1200} {
                @include fontsize(48);
            }
            @media #{$size_800} {
                @include fontsize(40);
            }
            @media #{$size_600} {
                @include fontsize(34);
            }
            @media #{$size_400} {
                @include fontsize(28);
            }

            span {
                @include fontsize(43);
                display: block;
                margin-bottom: 20px;
                @media #{$size_1200} {
                    @include fontsize(32);
                }
                @media #{$size_800} {
                    @include fontsize(26);
                }
                @media #{$size_600} {
                    @include fontsize(22);
                }
                @media #{$size_400} {
                    @include fontsize(18);
                }
            }
        }

        .subTitle {
            @include NotoSansCJKjp-Regular;
            @include fontsize(15);
            line-height: calc(27 / 15);
            padding-left: 20px;
            padding-right: 20px;
            box-sizing: border-box;

        }

        .point {
            display: inline-block;
            padding: 16px;
            border-radius: 100%;
            position: absolute;
            right: 55px;
            bottom: -35px;
            @include NotoSansCJKjp-Regular;
            @include fontsize(26);
            font-weight: bold;
            line-height: calc(32 / 26);
            color: #000;
            background: #ffcc00;
            @media #{$size_900} {
                @include fontsize(20);
                right: 20px;
                bottom: 20px;
            }
            @media #{$size_500} {
                margin-top: 20px;
                position: static;
            }
        }
    }

    .setList {
        color: #fff;
        max-width: 960px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        @media #{$size_1000} {
            padding: 0 15px;
        }
        @media #{$size_700} {
            display: block;
            padding: 0 10px;
        }

        .setCon {
            margin: 0 30px;
            width: 33.3333333333%;
            text-align: center;
            display: flex;
            flex-wrap: wrap;
            @media #{$size_1000} {
                margin: 0 20px;
            }
            @media #{$size_700} {
                display: block;
                width: 100%;
                padding: 0 15px 50px;
                margin: 0;
                box-sizing: border-box;
                text-align: left;
                overflow: hidden;
            }

            .img {
                order: 2;
                @media #{$size_700} {
                    width: 100px;
                    margin-left: 10px;
                    margin-right: 10px;
                    float: left;
                }

                img {
                    width: 100%;
                    margin-bottom: 18px;
                }
            }

            .catchphrase {
                /*display: flex;
      	flex-wrap: wrap;*/
                order: 1;
                width: 100%;
                margin-bottom: 13px;
                line-height: calc(24 / 15);
                @include fontsize(15);
                @include NotoSansCJKjp-Regular;
                @media #{$size_1000} {
                    br {
                        display: none;
                    }
                }
                @media #{$size_800} {
                    min-height: 5em;
                }
                @media #{$size_700} {
                    width: calc(100% - 110px);
                    margin-left: 110px;
                    min-height: inherit;
                }
            }

            .setName {
                order: 3;
                width: 100%;
                margin-bottom: 8px;
                line-height: calc(28 / 26);
                @include fontsize(26);
                @include YuMincho-36p-Kana;
                @media #{$size_700} {
                    width: calc(100% - 110px);
                    margin-left: 110px;

                    br {
                        display: none;
                    }
                }
            }

            .price {
                order: 4;
                width: 100%;
                margin-bottom: 18px;
                color: #ffcc00;
                @include fontsize(20);
                @media #{$size_700} {
                    width: calc(100% - 110px);
                    margin-left: 130px;
                }
            }

            .separate {
                order: 5;
                width: 100%;
                @media #{$size_700} {
                    width: calc(100% - 110px);
                    margin-left: 130px;
                }

                em {
                    color: #ffcc00;
                    margin-left: 5px;
                    @media #{$size_500} {
                        display: block;
                        padding-top: 5px;
                    }
                }
            }
        }
    }

    .setSize {
        max-width: 916px;
        padding: 6px;
        margin: 80px auto 0;
        @include NotoSansCJKjp-Regular;
        background: #fff;
        @media #{$size_700} {
            margin: 20px 15px 0;
        }

        .setSizeInner {
            display: flex;
            justify-content: space-between;
            padding: 30px 30px 15px;
            border: 2px solid #000;
            @media #{$size_700} {
                display: block;
            }

            .txtBox {
                text-align: left;

                p {
                    width: 13em;
                    @include fontsize(26);
                    line-height: calc(32/ 26);
                    font-weight: 500;
                    @media #{$size_700} {
                        width: 100%;

                        br {
                            display: none;
                        }
                    }
                }

                ul {
                    margin-top: 20px;
                    @media #{$size_700} {
                        margin-top: 10px;
                    }

                    li {
                        &::before {
                            content: "・";
                        }
                    }
                }
            }

            .imgBox {
                display: flex;
                max-width: 462px;
                width: 53%;
                text-align: center;
                @media #{$size_700} {
                    max-width: inherit;
                    width: 100%;
                    margin-top: 15px;
                }

                p {
                    margin-left: 18px;
                    @media #{$size_700} {
                        margin: 0 15px;
                    }

                    img {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }
}


.menu_box1 {
    .mainImg {
        span {
            img {
                width: 100%;
                height: auto;
            }
            &:nth-child(1) {
                display: block;
            }

            &:nth-child(2) {
                display: none;
            }
        }
    }
    @media #{$size_500} {
        .mainImg {
            span {
                &:nth-child(1) {
                    display: none;
                }

                &:nth-child(2) {
                    display: block;
                }
            }
        }
    }
}



.menu_box2 {
    .wrap {
        border: 3px solid #a67f48;
        background: url("/assets/images/common/unnamed.jpg") repeat;
        background-size: 280px;
        background-position: center;
        box-sizing: border-box;
        .item1 {
            text-align: right;
            position: relative;
            .item1_1 {
                position: absolute;
                top: -10px;
                left: -55px;
                .item1_1_wrap {
                    display: inline-block;
                    position: relative;
                    h3 {
                        position: absolute;
                        bottom: 32px;
                        right: 364px;
                        text-align: center;
                        display: inline-block;
                        width: 164px;
                        height: 164px;
                        border-radius: 100%;
                        line-height: 164px;
                        @include fontsize(60);
                        @include YuMincho-36p-Kana;
                        font-weight: bold;
                        color: #fff;
                        background: rgba(0, 0, 0, 0.8);
                    }
                    p {
                        width: calc(100% - 340px);
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }

            .item1_2 {
                display: inline-block;
                .item1_2_wrap {
                    width: 300px;
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: flex-start;
                    text-align: left;
                    margin-right: 40px;
                    margin-top: 50px;
                    h2 {
                        @include YuMincho-36p-Kana;
                        @include fontsize(86);
                        width: 1em;
                        padding-left: 30px;
                    }
                    p {
                        text-orientation: upright;
                        text-align: left;
                        line-height: 1.7647;
                        @include YuMincho-36p-Kana;
                        @include fontsize(17);
                    }
                }

            }

        }
    }
    @media #{$size_900} {
        .wrap {
            .item1 {
                .item1_1 {
                    .item1_1_wrap {
                        h3 {
                            bottom: 0px;
                            right: 350px;
                            width: 120px;
                            height: 120px;
                            line-height: 120px;
                            @include fontsize(40);
                        }
                    }
                }
            }
        }
    }
    @media #{$size_700} {

        .wrap {
            border: 2px solid #a67f48;
            .item1 {
                position: static;
                .item1_1 {
                    padding-top: 20px;
                    position: static;
                    .item1_1_wrap {
                        display: block;
                        position: relative;
                        h3 {
                            right: 22%;
                        }
                        p {
                            width: 350px;
                            margin-left: auto;
                            margin-right: auto;
                        }
                    }
                }
                .item1_2 {
                    margin-right: 27%;
                    .item1_2_wrap {
                        width: 300px;
                        margin-right: 0px;
                        margin-top: 30px;
                        h2 {
                            @include fontsize(65);
                            width: 1em;
                            padding-left: 30px;
                        }
                        p {
                            line-height: 1.6;
                            @include fontsize(16);
                        }
                    }

                }

            }
        }
    }
    @media #{$size_500} {

        .wrap {
            .item1 {
                .item1_1 {
                    .item1_1_wrap {
                        h3 {
                            right: 3%;
                            bottom: -10px;
                        }
                        p {
                            width: 280px;

                        }
                    }
                }
                .item1_2 {
                    margin-right: 17%;

                    .item1_2_wrap {
                        width: 250px;
                    }

                }

            }
        }
    }
    @media #{$size_400} {
        .wrap {
            .item1 {
                .item1_2 {
                    margin-right: 13%;

                }

            }
        }
    }
    @media #{$size_320} {
        .wrap {
            .item1 {
                .item1_2 {
                    margin-right: 5%;

                }

            }
        }
    }
}

.menu_box2 {
    .wrap {
        .item3_pr {
            max-width: 920px;
            padding-left: 10px;
            padding-right: 10px;
            box-sizing: border-box;
            margin-left: auto;
            margin-right: auto;
            ul {
                display: flex;
                li {
                    width: 31.5555555556%;
                    margin-right: 2.66666666667%;
                    &:last-child {
                        margin-right: 0%;
                    }
                    div {
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                    p {
                        padding-top: 10px;
                        .name {
                            @include fontsize(30);
                            font-weight: bold;
                            margin-right: 5px;
                            line-height: 1;
                            vertical-align: bottom;
                            @include YuMincho-36p-Kana;
                        }
                        .price {
                            color: #e80000;
                            vertical-align: text-bottom;
                            line-height: 1;
                            em {
                                @include fontsize(36);
                                font-weight: bold;
                                .yen {
                                    @include fontsize(24);
                                }
                            }
                            .tax {
                                margin-left: -15px;
                                @include fontsize(16);
                            }
                        }

                    }
                }
            }
        }
        .item2 {
            margin-top: 30px;
            padding-bottom: 74px;
            @include hack(ch) {
                margin-top: -140px;
            }
            @include hack(ed) {
                margin-top: 30px !important;
            }
        }
    }
    @media #{$size_900} {
        .wrap {
            .item3_pr {
                ul {
                    li {
                        p {
                            .name {
                                @include fontsize(28);
                                display: block;
                                padding-bottom: 6px;
                            }
                            .price {
                                em {
                                    @include fontsize(32);
                                    font-weight: bold;
                                    .yen {
                                        @include fontsize(20);
                                    }
                                }
                                .tax {
                                    @include fontsize(14);
                                }
                            }

                        }
                    }
                }
            }
        }
    }
    @media #{$size_700} {
        .wrap {
            .item3_pr {
                ul {
                    li {
                        p {
                            .name {
                                @include fontsize(24);
                            }
                            .price {
                                em {
                                    @include fontsize(28);
                                    .yen {
                                        @include fontsize(16);
                                    }
                                }
                                .tax {
                                    @include fontsize(13);
                                }
                            }

                        }
                    }
                }
            }
            .item2 {
                margin-top: -180px;
                padding-bottom: 60px;
                @include hack() {
                    margin-top: 0px;
                }
            }
        }
    }
    @media #{$size_500} {
        .wrap {
            .item3_pr {
                padding-left: 30px;
                padding-right: 30px;
                ul {
                    display: block;
                    li {
                        width: 100%;
                        margin-right: 0 !important;
                        padding-bottom: 30px;
                        &:last-child {
                            padding-bottom: 0px;
                        }
                    }
                }
            }
            .item2 {
                margin-top: -140px;
                @include hack() {
                    margin-top: 0px;
                }
            }
        }
    }
}

.menu_box2 {
    .wrap {
        .item3 {
            padding-bottom: 80px;
            position: relative;
            .catch {
                position: absolute;
                top: 0;
                left: 10%;
                text-orientation: upright;
                text-align: left;
                width: 120px;
                span {
                    display: inline-block;
                    margin-right: 10px;
                    background: #000;
                    color: #fff;
                    line-height: 32px;
                    padding: 10px 0;
                    text-align: left;
                    @include fontsize(24);
                    // font-weight: bold;
                    @include YuMincho;
                }
            }
            .img {
                max-width: 466px;
                margin: 0 auto;
                position: relative;
                h3 {
                    position: absolute;
                    top: 0;
                    right: -24%;
                    display: inline-block;
                    width: 164px;
                    height: 164px;
                    border-radius: 100%;
                    line-height: 164px;
                    @include fontsize(60);
                    font-weight: bold;
                    color: #fff;
                    background: rgba(0, 0, 0, 0.8);
                    @include YuMincho-36p-Kana;
                }
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .pricemain {
                position: absolute;
                bottom: 7%;
                right: 7%;
                display: inline-block;
                .pricetext {
                    text-align: left;
                    .name {
                        display: block;
                        margin-right: 5px;
                        @include YuMincho-36p-Kana;
                        @include fontsize(40);
                        font-weight: bold;
                        line-height: 1;
                    }
                    .price {
                        display: block;
                        color: #e80000;
                        em {
                            @include fontsize(46);
                            font-weight: bold;
                            .yen {
                                @include fontsize(30);
                            }
                        }
                        .tax {
                            @include fontsize(17);
                            margin-left: -15px;
                        }
                    }

                }
            }

        }
    }

    @media #{$size_900} {
        .wrap {
            .item3 {
                .catch {
                    z-index: 4;
                    left: 4%;
                    width: 120px;
                    span {
                        @include fontsize(22);
                    }
                }
                .img {
                    max-width: 466px;
                    h3 {
                        right: -10%;
                        width: 120px;
                        height: 120px;
                        line-height: 120px;
                        @include fontsize(40);
                    }
                }
                .pricemain {
                    bottom: 12%;
                    right: 6%;
                    .pricetext {
                        .name {
                            @include fontsize(36);
                        }
                        .price {
                            em {
                                @include fontsize(30);
                                .yen {
                                    @include fontsize(28);
                                }
                            }
                            .tax {
                                @include fontsize(15);
                                margin-left: -15px;
                            }
                        }

                    }
                }

            }
        }
    }
    @media #{$size_700} {
        .wrap {
            .item3 {
                padding-bottom: 60px;
                .catch {
                    position: static;
                    margin: 0 auto;
                    padding-bottom: 20px;
                    box-sizing: border-box;
                    span {
                        @include fontsize(22);
                    }
                    @include hack() {
                        padding-left: 48%;
                    }
                    @include hack(ed) {
                        padding-left: 28%;
                    }
                }
                .img {
                    h3 {
                        top: -16%;
                        right: 0%;
                    }
                    @include hack() {
                        margin-top: -370px;
                    }
                    @include hack(ed) {
                        margin-top: -460px;
                    }
                }
                .pricemain {
                    background-color: rgba(255, 255, 255, 0.8);
                    padding: 5px 10px;
                    box-sizing: border-box;
                    .pricetext {
                        .name {
                            @include fontsize(36);
                        }
                        .price {
                            em {
                                @include fontsize(30);
                                .yen {
                                    @include fontsize(28);
                                }
                            }
                            .tax {
                                @include fontsize(15);
                                margin-left: -15px;
                            }
                        }

                    }
                }

            }
        }
    }
}

.menu_box2 {
    .wrap {
        .item4 {
            position: relative;
            .img {
                position: relative;
                max-width: 640px;
                margin-left: -72px;
                h3 {
                    position: absolute;
                    top: 134px;
                    right: -14%;
                    display: inline-block;
                    width: 164px;
                    height: 164px;
                    border-radius: 100%;
                    line-height: 164px;
                    @include YuMincho-36p-Kana;
                    @include fontsize(60);
                    font-weight: bold;
                    color: #fff;
                    background: rgba(0, 0, 0, 0.8);
                }
                div {
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            .catch {
                position: absolute;
                top: 0;
                right: 10%;
                text-orientation: upright;
                text-align: left;
                width: 86px;
                height: 360px;
                span {
                    display: inline-block;
                    margin-right: 10px;
                    background: #000;
                    color: #fff;
                    line-height: 32px;
                    padding: 10px 0;
                    text-align: left;
                    @include fontsize(24);
                    // font-weight: bold;
                    @include YuMincho;
                    em {
                        // color: #e80000;
                        color: #fff;
                        i {
                            @include fontsize(18);
                        }
                    }
                }
            }
        }
    }
    @media #{$size_900} {
        .wrap {
            .item4 {
                .img {
                    margin-left: -60px;
                    h3 {
                        top: -7%;
                        left: 12%;
                        right: auto;
                        right: initial;
                        width: 120px;
                        height: 120px;
                        line-height: 120px;
                        @include fontsize(40);
                    }
                }
                .catch {
                    right: 6%;
                }
            }
        }
    }
    @media #{$size_700} {
        .wrap {
            .item4 {
                .img {
                    margin-left: -40px;
                    h3 {
                        top: -16%;
                        left: 10%;
                    }
                }
                .catch {
                    right: 4%;
                    height: 330px;
                    span {
                        @include fontsize(22);
                        em {
                            i {
                                @include fontsize(16);
                            }
                        }
                    }
                }
            }
        }
    }
    @media #{$size_500} {
        .wrap {
            .item4 {
                padding-bottom: 20px;
                .img {
                    max-width: 100%;
                    margin-left: 0;
                }
            }
        }
    }
}

.menu_box2 {
    .wrap {
        .item5 {
            padding-bottom: 90px;
        }
    }
    @media #{$size_700} {
        .wrap {
            .item5 {
                padding-bottom: 70px;
            }
        }
    }
}

.menu_box2 {
    .wrap {
        .item6 {
            position: relative;
            padding-bottom: 40px;
            .title {
                position: absolute;
                top: 0;
                right: 40px;
                h3 {
                    background: #000;
                    border-radius: 0;
                    display: inline-block;
                    line-height: 1;
                    @include fontsize(60);
                    padding: 0 25px;
                    position: relative;
                    z-index: 1;
                    -webkit-box-ordinal-group: 2;
                    -ms-flex-order: 1;
                    order: 1;
                    margin-right: 64px;
                    margin-top: 28px;
                    width: 111px;
                    height: 345px;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    margin-left: -2px;
                    @include YuMincho-36p-Kana;
                    font-weight: bold;
                    color: #fff;
                    em {
                        background: url(../images/menu/nikutsuke_bg.png) no-repeat;
                        position: absolute;
                        @include fontsize(28);
                        letter-spacing: 10px;
                        padding: 15px 10px 0;
                        top: -11px;
                        right: -35px;
                        -webkit-transform: rotate(6deg);
                        transform: rotate(6deg);
                    }
                }
            }

            .main_img {
                .img {
                    width: 100%;
                    padding-right: 178px;
                    box-sizing: border-box;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }

            .catch {
                position: absolute;
                top: -60px;
                left: 4%;
                text-orientation: upright;
                text-align: left;
                width: 86px;
                z-index: 4;
                height: 480px;
                span {
                    display: inline-block;
                    margin-right: 10px;
                    background: #000;
                    color: #fff;
                    line-height: 32px;
                    padding: 10px 0;
                    text-align: left;
                    @include fontsize(24);
                    // font-weight: bold;
                    @include YuMincho;
                }
            }

            .priceBox {
                position: absolute;
                top: 348px;
                right: 80px;
                width: 177px;
                height: 231px;
                height: 341px;
                z-index: 2;
                li {
                    background: #e80000;
                    border-radius: 100%;
                    color: #fff;
                    width: 127px;
                    height: 127px;
                    @include fontsize(20);
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    justify-content: center;
                    -webkit-box-ordinal-group: 3;
                    -ms-flex-order: 2;
                    order: 2;
                    position: absolute;
                    text-align: center;
                    p {
                        text-align: left;
                        padding-left: 8px;
                        letter-spacing: -1px;
                        .name {
                            text-align: center;
                            vertical-align: bottom;
                            line-height: 1;
                            width: 100%;
                            padding-bottom: 8px;
                            display: inline-block;
                            @include YuMincho-36p-Kana;
                            font-weight: bold;
                        }
                        .price {
                            display: inline-block;
                            position: relative;
                            width: 100%;
                            line-height: 1;
                            vertical-align: bottom;
                            text-align: left;
                            color: #e80000;
                            letter-spacing: -1px;
                            em {
                                @include fontsize(43);
                                color: #fff;
                                font-weight: bold;
                                .yen {
                                    @include fontsize(26);
                                }
                            }
                            .tax {
                                @include fontsize(12);
                                color: #fff;
                                position: absolute;
                                top: -2px;
                                right: 10px;
                                letter-spacing: 0;
                            }
                        }
                    }
                }
                .price01 {
                    left: 0;
                    top: 0;
                    -webkit-box-ordinal-group: 2;
                    -ms-flex-order: 1;
                    order: 1;
                }
                .price02 {
                    -webkit-box-ordinal-group: 3;
                    -ms-flex-order: 2;
                    order: 2;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
                .price03 {
                    -webkit-box-ordinal-group: 3;
                    -ms-flex-order: 3;
                    order: 3;
                    left: 0;
                    bottom: 0;
                }
            }

        }

    }

    @media #{$size_900} {
        .wrap {
            .item6 {
                padding-bottom: 60px;
                .title {
                    right: 60px;
                    h3 {
                        @include fontsize(40);
                        width: 90px;
                        height: 240px;
                        margin-right: 0px;
                        margin-top: 0px;
                        em {
                            @include fontsize(22);
                            top: -14px;
                            right: -25px;
                        }
                    }
                }

                .main_img {
                    .img {
                        padding-right: 151px;
                    }
                }

                .priceBox {
                    top: 216px;
                    right: 54px;
                }

            }

        }
    }
    @media #{$size_700} {
        .wrap {
            .item6 {
                padding-bottom: 10px;
                .title {
                    right: 20px;
                }

                .main_img {
                    .img {
                        padding-right: 111px;
                    }
                }
                .catch {
                    position: static;
                    padding-left: 10%;
                    span {
                        @include fontsize(22);
                    }
                }

                .priceBox {
                    right: 4%;
                }

            }

        }
    }
    @media #{$size_400} {
        .wrap {
            .item6 {

                .main_img {
                    .img {
                        padding-right: 90px;
                    }
                }
                .catch {
                    padding-left: 1%;
                }

            }

        }
    }
}

.menu_box4 {
    .menu_box4_p1 {
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 40px;
        padding-bottom: 10px;
        color: #fff;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        line-height: 1.8;
    }
    @media #{$size_700} {
        .menu_box4_p1 {
            text-align: left;
            @include fontsize(14);
        }
    }
}
