@charset "utf-8";

// メニューアイコンクリック時
@keyframes menuIcon_centerBar {
  0% {
    background-color: transparent;
    height: 0;
    left: 20%;
  }
  100% {
    background-color: #a67f48;
    height: 4px;
    left: 0;
  }
}

@keyframes menuIcon_centerBar_active {
  0% {
    background-color: #a67f48;
    height: 4px;
    left: 0;
  }
  100% {
    background-color: transparent;
    height: 0;
    left: 20%;
  }
}


//section.appeal の animation
@keyframes fadeIn_fromBtm {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeIn_downSize {
  0% {
    opacity: 0;
    transform: translateY(-80px) scale(1.5);
  }
  100% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}

@keyframes bounce {
  0%, 100%, 20%, 50%, 80% {
      transform: translateY(0px);
  }
  40% {
      transform: translateY(-30px);
  }
  60% {
      transform: translateY(-15px);
  }
}

@keyframes fadeIn_fromRight {
  0% {
    opacity: 0;
    transform: translateX(80px) scale(1.2);
  }
  100% {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }
}

@keyframes fadeIn_fromLeft {
  0% {
    opacity: 0;
    transform: translateX(-80px) scale(1.2);
  }
  100% {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }
}

@keyframes fadeIn_fromTopRight {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes opacity0-1 {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

//section.menuIntro
@keyframes translateX_-80-0 {
  0% {
    opacity: 0;
    transform: translateX(-80px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes translateX_80-0 {
  0% {
    opacity: 0;
    transform: translateX(80px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

//index.php section.topicNews
@keyframes prawnDown {
  0% {
    transform: rotate(-6deg) scale(1.2);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}

@keyframes pu {
  0% {
    transform: rotate(-6.3rad) scale(1) translateY(0);
    @include fontsize(14);
  }
  50% {
    transform: rotate(-6.3rad) scale(2) translateX(-30px) translateY(-70px);
    @include fontsize(30);
  }
  100% {
    transform: rotate(-6.3rad) scale(1) translateY(0);
    @include fontsize(14);
  }
}

@keyframes ri {
  0% {
    transform: rotate(-6.37rad) scale(1) translateY(0);
    @include fontsize(14);
  }
  50% {
    transform: rotate(-6.37rad) scale(2) translateX(-15px) translateY(-50px);
    @include fontsize(24);
  }
  100% {
    transform: rotate(-6.37rad) scale(1) translateY(0);
    @include fontsize(14);
  }
}

@keyframes ttu {
  0% {
    transform: rotate(-6.42rad) scale(1) translateY(0);
    @include fontsize(14);
  }
  50% {
    transform: rotate(-6.42rad) scale(2) translateY(-50px);
    @include fontsize(24);
  }
  100% {
    transform: rotate(-6.42rad) scale(1) translateY(0);
    @include fontsize(14);
  }
}

@keyframes other-to {
  0% {
    transform: rotate(-6.47rad) scale(1) translateY(0);
    @include fontsize(14);
  }
  50% {
    transform: rotate(-6.47rad) scale(1.2) translateY(-20px);
    @include fontsize(20);
  }
  100% {
    transform: rotate(-6.47rad) scale(1) translateY(0);
    @include fontsize(14);
  }
}

@keyframes other-shin {
  0% {
    transform: rotate(-6.52rad) scale(1) translateY(0);
    @include fontsize(14);
  }
  50% {
    transform: rotate(-6.52rad) scale(1.2) translateX(-10px) translateY(-30px);
    @include fontsize(20);
  }
  100% {
    transform: rotate(-6.52rad) scale(1) translateY(0);
    @include fontsize(14);
  }
}

@keyframes other-sen {
  0% {
    transform: rotate(-6.56rad) scale(1) translateY(0);
    @include fontsize(14);
  }
  50% {
    transform: rotate(-6.56rad) scale(1.2) translateY(-30px);
    @include fontsize(20);
  }
  100% {
    transform: rotate(-6.56rad) scale(1) translateY(0);
    @include fontsize(14);
  }
}

@keyframes other-na {
  0% {
    transform: rotate(-6.61rad) scale(1) translateY(0);
    @include fontsize(14);
  }
  50% {
    transform: rotate(-6.61rad) scale(1.2) translateY(-30px);
    @include fontsize(20);
  }
  100% {
    transform: rotate(-6.61rad) scale(1) translateY(0);
    @include fontsize(14);
  }
}

@keyframes other-o {
  0% {
    transform: rotate(-6.66rad) scale(1) translateY(0);
    @include fontsize(14);
  }
  50% {
    transform: rotate(-6.66rad) scale(1.2) translateY(-15px);
    @include fontsize(18);
  }
  100% {
    transform: rotate(-6.66rad) scale(1) translateY(0);
    @include fontsize(14);
  }
}

@keyframes other-shi {
  0% {
    transform: rotate(-6.72rad) scale(1) translateY(0);
    @include fontsize(14);
  }
  50% {
    transform: rotate(-6.72rad) scale(1.2) translateY(-15px);
    @include fontsize(18);
  }
  100% {
    transform: rotate(-6.72rad) scale(1) translateY(0);
    @include fontsize(14);
  }
}

@keyframes other-ra {
  0% {
    transform: rotate(-6.79rad) scale(1) translateY(0);
    @include fontsize(14);
  }
  50% {
    transform: rotate(-6.79rad) scale(1.2) translateY(-15px);
    @include fontsize(18);
  }
  100% {
    transform: rotate(-6.79rad) scale(1) translateY(0);
    @include fontsize(14);
  }
}

@keyframes other-se {
  0% {
    transform: rotate(-6.89rad) scale(1) translateY(0);
    @include fontsize(14);
  }
  50% {
    transform: rotate(-6.89rad) scale(1.2) translateY(-15px);
    @include fontsize(18);
  }
  100% {
    transform: rotate(-6.89rad) scale(1) translateY(0);
    @include fontsize(14);
  }
}

@keyframes other-end {
  0% {
    transform: rotate(-7.06rad) scale(1) translateY(0);
    @include fontsize(14);
  }
  50% {
    transform: rotate(-7.06rad) scale(1.2) translateY(-15px);
    @include fontsize(20);
  }
  100% {
    transform: rotate(-7.06rad) scale(1) translateY(0);
    @include fontsize(14);
  }
}
