@charset "UTF-8";

html{
	font-size: 16px;
	margin: 0;
	padding:0;
}
* {
	-webkit-appearance: none;
}


body{
	color:#252525;
	line-height:1.4;
	text-align: center;
	z-index: 0;
	word-break : break-all;
	position: relative;
	z-index: 0;
	overflow-x:hidden;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
	background-repeat: no-repeat;
	-webkit-text-size-adjust: 100%;
}


body, div, h1, h2, h3, h4, h5, h6, p, dl, dt, dd, ul, ol, li,
brockquote, pre, table, caption, th, td, address,
form, fieldset, legend, object {
  margin: 0;
  padding: 0;
  font-weight:normal;
}

h1,h2,h3,h4,h5{
	margin: 0;
}

////////////　フォ－ム
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"]
{
	font-family: 'メイリオ',Meiryo,'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro',sans-serif;
}

li{
	list-style:none;
}

a{
	transition: all 0.1s;
	-webkit-transform: translatez(0);
	color:#333;
}

a:link,
a:visited,
a:active,
a:hover {
	outline: none;
	transition: all 0.1s;
	color:#333;
	text-decoration: none;
}


a:hover img{
	opacity: 0.8;
	cursor:pointer;
}

a, ins {
	text-decoration: none;
}

address, em, cite, dfn, var {
  font-style: normal;
}

input, textarea {
  font-family: sans-serif;
  font-weight: normal;
}

img{
	vertical-align:bottom;
}

a img {
  border: none;
}

.o_main {
	overflow: hidden;
}


@font-face {
  font-family: 'NotoSansCJKjp-Black';
  /*01*/
  src: url("/assets/font/NotoSansCJKjp-Black.woff");
}
@font-face {
  font-family: 'NotoSansCJKjp-Bold';
  /*02*/
  src: url("/assets/font/NotoSansCJKjp-Bold.woff");
}
@font-face {
  font-family: 'NotoSansCJKjp-DemiLight';
  /*03*/
  src: url("/assets/font/NotoSansCJKjp-DemiLight.woff");
}
@font-face {
  font-family: 'NotoSansCJKjp-Light';
  /*04*/
  src: url("/assets/font/NotoSansCJKjp-Light.woff");
}
@font-face {
  font-family: 'NotoSansCJKjp-Medium';
  /*05*/
  src: url("../font/NotoSansCJKjp-Medium.woff");
}
@font-face {
  font-family: 'NotoSansCJKjp-Regular';
  /*06*/
  src: url("../font/NotoSansCJKjp-Regular.woff");
}
@font-face {
  font-family: 'NotoSansCJKjp-Thin';
  /*07*/
  src: url("/assets/font/NotoSansCJKjp-Thin.woff");
}
