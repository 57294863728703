@charset "utf-8";

//section.shop_mainVisual
section.shop_mainVisual {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media #{$size_1000} {
    max-width: 800px;
  }
  @media #{$size_800} {
    display: block;
  }

  div.shop_mainVisualArea {
    width: 50%;
    @media #{$size_800} {
      width: 85%;
      margin: 0 auto;
    }
    @media #{$size_600} {
      width: auto;
    }

    div.imgBox {
        img {
          width: 100%;
          display: block;
        }
    }
  }
}


div.shop_mainVisualArea {
  p.mainVisual_textArea {
    width: 90px;
    height: 430px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    @media #{$size_1000} {
      width: 80px;
      height: 372px;
    }
    @media #{$size_800} {
      width: 85px;
      height: 400px;
    }
    @media #{$size_600} {
      width: 78px;
      height: 340px;
    }
    @media #{$size_400} {
      width: 74px;
      height: 283px;
    }

    span.vertical-writing {
      display: inline-block;
      align-self: flex-start;
      text-align: center;
      @include YuMincho;
      @include fontsize(26);
      font-weight: normal;
      color: #fff;
      background-color: #000;
      margin-right: 10px;
      padding: 20px 2px;
      word-wrap: normal;
      box-sizing: border-box;
      @media #{$size_1000} {
        @include fontsize(22);
      }
      @media #{$size_800} {
        @include fontsize(24);
      }
      @media #{$size_600} {
        @include fontsize(20);
      }
      @media #{$size_400} {
        @include fontsize(16);
      }

      &:first-child {
        margin-right: 0;
      }
    }

    @media screen\0 {
      span.vertical-writing {
        line-height: 1.8;
      }
    }

  }
}


section.shop_mainVisual div.shop_mainVisual_leftSide {
  position: relative;
  @media #{$size_800} {
    margin-bottom: 50px;
  }
  @media #{$size_600} {
    margin-bottom: 30px;
  }

  p.mainVisual_textArea {
    position: absolute;
    top: -2.5rem;
    left: 2.5rem;
    @media #{$size_800} {
      top: -1rem;
    }
    @media #{$size_400} {
      left: 1rem;
    }
  }
}

section.shop_mainVisual div.shop_mainVisual_rightSide {
  position: relative;

  p.mainVisual_textArea {
    position: absolute;
    bottom: -2.5rem;
    right: 2rem;
    @media #{$size_1000} {
      bottom: -2.5rem;
    }
    @media #{$size_800} {
      bottom: -1.5rem;
    }
    @media #{$size_400} {
      bottom: -2.5rem;
      right: 0.5rem;
    }
  }
}

//section.shop_locationByArea
section.shop_locationByArea {
  padding: 0 20px 210px;
  max-width: 1040px;
  margin: 0 auto;
  box-sizing: border-box;
  @media #{$size_1100} {
    max-width: none;
  }
}


h2.shop_area_title {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 90px;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 130px;
    background-color: #000;
    border-radius: 50%;
    @include YuMincho;
    @include fontsize(26);
    font-weight: normal;
    color: #fff;
    letter-spacing: 0.5px;
  }
}


section.shop_location {
  padding-bottom: 30px;
  margin: 0 auto;
}


ul.shopList {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  @media #{$size_700} {
    justify-content: center;
  }

  li.shop_list-item {
    width: 31%;
    margin-right: 3.5%;
    margin-bottom: 100px;
    @media #{$size_1000} {
      width: 47%;
      margin-right: 5%;
    }
    @media #{$size_700} {
      width: 80%;
      margin-right: 0;
      margin-bottom: 80px;
    }
    @media #{$size_600} {
      width: 90%;
    }
    @media #{$size_400} {
      width: auto;
    }


    &:nth-child(3n) {
      margin-right: 0;
      @media #{$size_1000} {
        margin-right: 5%;
      }
      @media #{$size_700} {
        margin-right: 0;
      }
    }

    &:nth-child(2n) {
      @media #{$size_1000} {
        margin-right: 0;
      }
    }

  }
}


//section.shop_locationByArea dl.list_info-main
dl.list_info-main {
  text-align: left;
  padding-bottom: 10px;

  dd.shop-IMG {
    padding-bottom: 15px;

    div.imgBox {
      img{
        display: block;
        width: 100%;
      }
    }
  }

  dt.shop-name {
    @include NotoSansCJKjp-Bold;
    @include fontsize(18);
    color: #000;
    line-height: 1.6;
  }

  dd.shop-tel {
    @include NotoSansCJKjp-Medium;
    @include fontsize(18);
    color: #000;
    line-height: 1.6;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 30px;

    &::before {
      display: block;
      content:'';
      background-image: url('../images/shop/shop_telMark.png');
      background-repeat: no-repeat;
      background-size: contain;
      width: 21px;
      height: 17px;
      position: absolute;
      left: 0;

    }
  }

  dd.shop-linkToMap {
    a {
      @include NotoSansCJKjp-Bold;
      @include fontsize(13);
      color: #000;
      display: flex;
      justify-content: center;
      position: relative;

      &::after {
        display: block;
        content:'';
        background-image: url('../images/shop/shop_mapMark.png');
        background-repeat: no-repeat;
        background-size: contain;
        width: 27px;
        height: 31px;
        position: absolute;
        top: 15px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  dd.shop-address {
    @include NotoSansCJKjp-Regular;
    @include fontsize(15);
    color: #000;
    @media #{$size_1100} {
      @include fontsize(14);
    }
  }
}


dl.list_info-main {
  div.info-main_2column {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
  }
}


//section.shop_locationByArea div.list_info-detail
div.list_info-detail {

  dl {
    display: flex;

    dt, dd {
      @include NotoSansCJKjp-Regular;
      @include fontsize(15);
      color: #000;
      line-height: 1.8;
      text-align: left;
      padding-left: 7px;
      @media #{$size_1100} {
        @include fontsize(14);
      }
    }

    dt {
      background-color: rgba(166, 127, 72, 0.3);
      width: 23%;
    }

    dd {
      display: inline-block;

      &:not(:first-of-type){
        padding-left: 12px;
        @media #{$size_400} {
          padding-left: 4px;
        }
      }
    }
  }
}
