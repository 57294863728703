@charset "UTF-8";

footer {
    background-image: url("../images/footer/back.png");
    background-size: 96px;
    background-repeat: repeat;
}

div.footer_contents-wrapper {
    max-width: 1000px;
    padding: 30px 0;
    margin: 0 auto;
    @media #{$size_1200} {
        max-width: 900px;
    }
    @media #{$size_1000} {
        max-width: 700px;
    }
    @media #{$size_800} {
        max-width: 600px;
        padding: 30px 20px;
    }
}

h2.footer_title {
    margin: 0 auto;
    max-width: 270px;
    padding-bottom: 20px;
    @media #{$size_1200} {
        max-width: none;
        width: 250px;
    }
    @media #{$size_800} {
        width: 220px;
    }
    @media #{$size_800} {
        width: 180px;
    }
    @media #{$size_700} {
        margin: 0 auto;
        width: 180px;
    }

    a {
        display: block;
        width: 100%;

        img {
            display: block;
            width: 100%;
        }
    }
}

nav.footer_nav {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
    @media #{$size_700} {
        margin-bottom: 30px;
    }

    ul {
        display: flex;
        @media #{$size_700} {
            display: block;
        }

        li {
            margin-right: 35px;
            @media #{$size_800} {
                margin-right: 20px;
            }
            @media #{$size_700} {
                margin-right: 0;
                padding: 10px 0;
            }

            &:last-child {
                margin-right: 0;
            }

            a {
                display: block;
                @include fontsize(14);
                @include NotoSansCJKjp-Regular;
                letter-spacing: 1px;
                color: #fff;
                position: relative;
                &::after {
                    content: "";
                    display: block;
                    width: 1px;
                    height: 1px;
                    background-color: transparent;
                    position: absolute;
                    bottom: -6px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    @include transition02;
                }
                &:hover {
                    &::after {
                        width: 100%;
                        background-color: #fff;
                        @include transition02;
                    }
                }
                @media #{$size_700} {
                    display: inline-block;
                }
            }
        }
    }

}

div.footer_shopInfo {
    margin-bottom: 20px;
    @media #{$size_700} {
        margin-bottom: 50px;
    }

    h3.shopInfo_title {
        @include fontsize(30);
        @include YuMincho-36p-Kana;
        font-weight: normal;
        color: #515151;
        text-align: left;
        padding-bottom: 15px;
        @media #{$size_700} {
            @include fontsize(24);
            text-align: center;
            padding-bottom: 10px;
        }
    }

    ul.shopList {
        display: flex;
        flex-wrap: wrap;
        @media #{$size_700} {
            margin-left: 20%;
        }
        @media #{$size_400} {
            margin-left: 10%;
        }

        li {
            margin-right: 35px;
            line-height: 2;
            @media #{$size_700} {
                margin-right: 0;
                line-height: 2.5;
                width: 50%;
                text-align: left;
            }

            a {
                display: block;
                @include fontsize(14);
                @include NotoSansCJKjp-Regular;
                letter-spacing: 1px;
                color: #fff;
                @media #{$size_700} {
                    letter-spacing: 0;
                }
            }
        }
    }
}

p.footer_pr-comment {
    text-align: left;
    margin-bottom: 125px;
    text-align: center;
    @media #{$size_700} {
        margin-bottom: 80px;
    }

    span {
        padding: 10px 30px;
        box-sizing: border-box;
        border: 2px solid #ccc;
        display: inline-block;
        @include fontsize(20);
        @include YuMincho;
        letter-spacing: 2px;
        color: #fff;
        @media #{$size_700} {
            padding: 10px 20px;
            @include fontsize(16);
            letter-spacing: 1px;
        }
        @media #{$size_600} {
            @include fontsize(14);
        }
    }
}

small.footer_copyright {
    @include fontsize(12);
    @include NotoSansCJKjp-Medium;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    span.copyrightMark {
        @include fontsize(18);
    }
}
// pagetop-btn
div.pagetop-btn {
    position: fixed;
    bottom: 75px;
    right: 75px;
    width: 22px;
    height: 71px;
    z-index: 1000;
    @media #{$size_600} {
        bottom: 50px;
        right: 28px;
        width: 15px;
        height: auto;
    }

    a {
        display: block;
        width: 100%;
        height: 100%;

        img {
            display: block;
            width: 100%;
        }
    }
}

.footer_company {
    padding-bottom: 10px;
    a {
        @include NotoSansCJKjp-Medium;
        @include fontsize(13);
        color: #fff;
        position: relative;
        i {
            position: absolute;
            top: -7px;
            left: 0;
            display: inline-block;
            width: 11px;
            img {
                width: 100%;
                height: auto;
            }
        }
        span {
            padding-left: 24px;
            display: inline-block;
            box-sizing: border-box;
        }
        &:hover {
            opacity: 0.6;
        }
    }
    @media #{$size_700} {
        padding-bottom: 5px;
    }
}