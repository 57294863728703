@charset "utf-8";
$img_common:'/common/images/common/';
$size_2000: "only screen and (max-width: 2000px)";
$size_1900: "only screen and (max-width: 1900px)";
$size_1800: "only screen and (max-width: 1800px)";
$size_1700: "only screen and (max-width: 1700px)";
$size_1600: "only screen and (max-width: 1600px)";
$size_1600min: "only screen and (min-width: 1600px)";
$size_1500: "only screen and (max-width: 1500px)";
$size_1400: "only screen and (max-width: 1400px)";
$size_1300: "only screen and (max-width: 1300px)";
$size_1200: "only screen and (max-width: 1200px)";
$size_1160: "only screen and (max-width: 1160px)";
$size_1100: "only screen and (max-width: 1100px)";
$size_1000: "only screen and (max-width: 1000px)";
$size_min1000: "only screen and (min-width: 1000px)";
$size_960: "only screen and (max-width: 960px)";
$size_900: "only screen and (max-width: 900px)";
$size_800: "only screen and (max-width: 800px)";
$size_700: "only screen and (max-width: 700px)";
$size_600: "only screen and (max-width: 600px)";
$size_550: "only screen and (max-width: 550px)";
$size_500: "only screen and (max-width: 500px)";
$size_480: "only screen and (max-width: 480px)";
$size_400: "only screen and (max-width: 400px)";
$size_374: "only screen and (max-width: 374px)";
$size_320: "only screen and (max-width: 320px)";
$size_300: "only screen and (max-width: 300px)";

$size_min_1000: "only screen and (min-width: 1000px)";

// ex @media #{$size_1200}{


@mixin fontsize($size: 24, $base: 16) {
	font-size: $size + px;
	font-size: ($size / $base) * 1rem;
}

/* font */

// @mixin x01{
// 	font-family:Ryumin Regular KL;
// 	//font-family: 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
// }
//
// @mixin x02{
// 	font-family:Ryumin Regular KL;
// 	//font-family: 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
// 	//font-family: 'メイリオ',Meiryo,'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro',sans-serif;
// }
//
// @mixin x03{
// 	//font-family:Ryumin Regular KL;
// 	//font-family: 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
// 	font-family: 'メイリオ',Meiryo,'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro',sans-serif;
// }
@mixin gothic1 {
	font-family: メイリオ, Meiryo, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", sans-serif;
}


//NotoSansCJKjp-Medium
@mixin NotoSansCJKjp-Medium {
	font-family: 'NotoSansCJKjp-Medium', sans-serif;
}

//NotoSansCJKjp-Regular
@mixin NotoSansCJKjp-Regular {
	font-family: 'NotoSansCJKjp-Regular', sans-serif;
}

//NotoSansCJKjp-Bold
@mixin NotoSansCJKjp-Bold {
	font-family: 'NotoSansCJKjp-Bold', sans-serif;
}

//YuMincho +36p Kana
@mixin YuMincho-36p-Kana {
	font-family: 'YuMincho +36p Kana', 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
}

//游明朝
@mixin YuMincho {
	font-family: 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
}

// Century の代替フォント
@import url('https://fonts.googleapis.com/css?family=Noto+Serif');

@mixin Num {
// font-family: 'Muli', sans-serif;
	font-family: 'Century', 'Noto Serif', serif;
}



@mixin transition01{
	transition: 0.2s cubic-bezier(.165,.84,.44,1);
}
@mixin transition0-3{
	transition: 0.3s cubic-bezier(.165,.84,.44,1);
}
@mixin transitionbtn{
	transition: 3s cubic-bezier(.165,.84,.44,1);
}


@mixin transition02{
	transition: 0.4s cubic-bezier(.165,.84,.44,1);
}
@mixin transition03{
	transition: 5s cubic-bezier(.165,.84,.44,1);
}


@media all and (-ms-high-contrast: none){
  .part_must {
    padding-top: 6px;
    height: auto;
  }
}

/* IE＆Firefoxハック用mixin */
@mixin hack($ver: 11) {
  	@if $ver == 11 { // IE11以上
    	@at-root _:-ms-fullscreen,:root & {
      		@content;
    	}
  	}
  	@else if $ver == fox { // Firefox
    	@-moz-document url-prefix() {
      		@content;
    	}
  	}
  	@else if $ver == ch { // Chrome
    	@media screen and (-webkit-min-device-pixel-ratio:0){
      		@content;
    	}
  	}
  	@else if $ver == ed { // Edge
    	@supports (-ms-ime-align:auto) {
      		@content;
    	}
  	}
}
