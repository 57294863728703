@charset "UTF-8";

.header_title {
  max-width: 308px;
  margin: 0 auto;
  padding: 40px 0;
  @media #{$size_1200} {
    max-width: none;
    width: 280px;
  }
  @media #{$size_800} {
    max-width: none;
    width: 240px;
  }
  @media #{$size_700} {
      margin: 0;
      padding-left: 20px;
      box-sizing: border-box;
      padding-top: 20px;
      padding-bottom: 20px;
  }
  @media #{$size_400} {
    max-width: none;
    width: 180px;
  }

  a {
    display: block;
    width: 100%;

    img {
      display: block;
      width: 100%;
    }
  }
}


// .header nav
nav.globalNav {
  max-width: 1020px;
  display: block;
  margin: 0 auto;
  padding-bottom: 30px;
  @media #{$size_1200} {
    max-width: none;
    padding: 0 20px 30px;
  }
  @media #{$size_1000} {
    padding: 0 0 30px;
  }
  @media #{$size_700} {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    // bottom: 0;
    // height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 500;
  }

  ul.nav_menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    @media #{$size_1200} {
      justify-content: center;
      flex-direction: row;
    }
    @media #{$size_700} {
      flex-direction: column;
      justify-content: center;
      width: 100%;
      // overflow-x: hidden;
      background-color: #ebe4da;
      display: none;

    }

    div.mobile_nav_logo {
        @media #{$size_700} {
        margin: 0 auto;
        width: 240px;
        padding: 22px 0 40px;

        img {
          display: block;
          width: 100%;
        }
      }

      @media #{$size_400} {
        width: 160px;
        padding: 20px 0 40px;
      }
    }

    li.menu_list {
      display: flex;
      justify-content: center;
      align-items: center;
      @media #{$size_1200} {
        margin-right: 80px;

        &:last-child {
          margin-right: 0;
        }
      }

      @media #{$size_1000} {
        margin-right: 55px;
      }

      @media #{$size_800} {
        margin-right: 40px;
      }

      @media #{$size_700} {
        margin-right: 0;
        width: 100%;
        border-bottom: 1px solid #a67f48;
      }


      a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media #{$size_700} {
          width: 100%;
          padding: 20px;
          box-sizing: border-box;
        }
        @media #{$size_400} {
          width: 100%;
          padding: 10px;
          box-sizing: border-box;
        }

        span {
          display: block;
          @include fontsize(15);
          font-weight: bold;
          @include NotoSansCJKjp-Medium;
          letter-spacing: 2px;
          color: #000;
          @media #{$size_1000} {
            letter-spacing: 1px;
            margin-bottom: 5px;
          }
          @media #{$size_800} {
            @include fontsize(14);
            margin-bottom: 3px;
          }
          @media #{$size_700} {
            @include fontsize(15);
            letter-spacing: 2px;
          }
        }

        p {
          @include YuMincho-36p-Kana;
          @include fontsize(16);
          font-weight: 800;
          color: #4b370f;
          letter-spacing: 2px;
          @media #{$size_1000} {
            @include fontsize(14);
            letter-spacing: 1px;
          }
          @media #{$size_700} {
            @include fontsize(16);
            letter-spacing: 2px;
          }

        }

      }

    }
  }
}


@media only screen and (min-width: 701px) {
    nav.globalNav {

      ul.nav_menu {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        flex-direction: row !important;
      }
  }
}

@media only screen and (min-width: 1201px) {
    nav.globalNav {

      ul.nav_menu {
        justify-content: space-between !important;
      }
  }
}


// .header nav mobile_menuIcon
div.mobile_menuIconArea span.mobile_menuIcon {
  display: none;

  @media #{$size_700}{
    width: 50px;
    height: 50px;
    position: fixed;
    top: 15px;
    right: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 9999 !important;
    @media #{$size_400} {
      width: 40px;
      height: 40px;
    }

    i.menuIcon_centerBar {
      display: block;
      width: 36px;
      height: 4px;
      border-radius: 2px;
      background-color: #a67f48;
      position: relative;
      animation: menuIcon_centerBar .3s forwards;

      &::before {
        display: block;
        content: '';
        width: 100%;
        height: 4px;
        background-color: #a67f48;
        border-radius: 2px;
        position: absolute;
        bottom: 12px;
        transition: all .3s;
      }

      &::after {
        display: block;
        content: '';
        width: 100%;
        height: 4px;
        background-color: #a67f48;
        border-radius: 2px;
        position: absolute;
        top: 12px;
        transition: all .3s;
      }

    }
  }
}

//メニューアイコンクリック時
div.mobile_menuIconArea span.mobile_menuIcon.active {
  display: none;

  @media #{$size_700}{
    width: 50px;
    height: 50px;
    position: fixed;
    top: 15px;
    right: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 999;
    @media #{$size_400} {
      width: 40px;
      height: 40px;
    }

    i.menuIcon_centerBar {
      display: block;
      width: 36px;
      height: 4px;
      border-radius: 2px;
      background-color: #a67f48;
      position: relative;
      animation: menuIcon_centerBar_active .3s forwards;

      &::before {
        display: block;
        content: '';
        width: 100%;
        height: 4px;
        background-color: #a67f48;
        border-radius: 2px;
        position: absolute;
        bottom: 12px;
        transform-origin: center;
        transform:  translateY(14px) translateX(-30%) rotate(45deg);
        transition: all .3s;
      }

      &::after {
        display: block;
        content: '';
        width: 100%;
        height: 4px;
        background-color: #a67f48;
        border-radius: 2px;
        position: absolute;
        top: 12px;
        transform-origin: center;
        transform:  translateY(-14px) translateX(-30%) rotate(-45deg);
        transition: all .3s;
      }

    }
  }
}

//div.header-contents.contents_fixed
@media only screen and (min-width: 701px) {
  div.header-contents {
    transition: all .3s;
  }
}

@media only screen and (min-width: 701px) {
  div.header-contents.contents_fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: #ece5db;
    margin: 0 auto;
    padding: 0 20px;
    max-width: none;
    transition: all .3s;

    div.header-contents_wrapper{
      max-width: 1000px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      @media #{$size_1200} {
        justify-content: center;
      }
      @media #{$size_1000} {
        display: block;
      }

      .header_title {
        max-width: none;
        width: 200px;
        padding: 20px 20px;
        margin: 0;
        @media #{$size_1200} {
          width: 180px;
          margin-right: 30px;
        }
        @media #{$size_1000} {
          padding: 10px 10px 12px;
          margin: 0 auto;
        }


        a {
          display: block;
          width: 100%;

          img {
            display: block;
            width: 100%;
          }
        }
      }

      nav.globalNav {
        margin: 0;
        padding: 0;
        @media #{$size_1000} {
          margin-bottom: 10px;
        }

        ul.nav_menu {
            padding-top: 2px;
          li.menu_list {
            margin-right: 40px;
            @media #{$size_1200} {
              margin-right: 30px;
            }
            @media #{$size_1000} {
              margin-right: 40px;
            }

            &:last-child {
              margin-right: 0;
            }

            a {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              span {
                display: block;
                @include fontsize(14);
                font-weight: bold;
                @include NotoSansCJKjp-Medium;
                letter-spacing: 2px;
                color: #000;
                @media #{$size_1000} {
                  letter-spacing: 0;
                }
              }

              p {
                @include YuMincho-36p-Kana;
                @include fontsize(14);
                font-weight: 800;
                color: #4b370f;
                letter-spacing: 1px;
                @media #{$size_1000} {
                  letter-spacing: 0px;
                }
              }

            }
          }
        }
      }
    }
  }
}

// hover effect
nav.globalNav ul.nav_menu li.menu_list a {
  position: relative;
  text-decoration: none;

  &::after {
     position: absolute;
     bottom: -4px;
     left: 0;
     content: '';
     width: 100%;
     height: 2px;
     background: #a67f48;
     transform: scale(0, 1);
     transform-origin: right top;
     transition: transform .3s;
     @media #{$size_700} {
       content: normal;
    }
  }

  &:hover {
    &::after {
      transform-origin: left top;
      transform: scale(1, 1);
    }
  }
}
