@charset "UTF-8";

.qual_title {
    padding-top: 50px;
    padding-bottom: 50px;
    @media #{$size_700} {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

.qual_subtitle {
    width: 285px;
    height: 285px;
    border-radius: 50%;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin-bottom: 70px;
    span {
        font-weight: 500;
        display: inline-block;
        @include YuMincho;
        line-height: 1.6;
    }
    @media #{$size_700} {
        width: 220px;
        height: 220px;
        margin-bottom: 50px;
    }
    @media #{$size_400} {
        width: 210px;
        height: 210px;
    }
}

.qual_main {
    article {
        color: #000;
        background-image: url("../images/common/unnamed.jpg");
        background-size: 280px;
        background-repeat: repeat;
        position: relative;
        margin-top: -22%;
        padding-top: 90px;
        .num {
            position: absolute;
            top: -60px;
            left: 0;
            right: 0;
            margin: auto;
            display: inline-block;
            width: 120px;
            height: 120px;
            background-color: #000;
            border-radius: 50%;
            color: #fff;
            @include YuMincho-36p-Kana;
            background-image: url("../images/quality/arrow1.png");
            background-size: 15px;
            background-repeat: no-repeat;
            background-position: 50% 82%;
            span {
                @include fontsize(30);
                display: inline-block;
                line-height: 1.2;
                padding-top: 6px;
                i {
                    @include fontsize(20);
                }
            }
        }
        header {
            h2 {
                font-weight: 500;
                @include YuMincho-36p-Kana;
                padding-bottom: 20px;
            }
        }
        .content {
            text-align: left;
            max-width: 640px;
            margin-left: auto;
            margin-right: auto;
            padding-left: 20px;
            padding-right: 20px;
            .subtitle {
                @include YuMincho;
                padding-bottom: 10px;
                font-weight: 500;
            }
        }
    }
    @media #{$size_1400} {
        article {
            margin-top: -15%;
        }
    }
    @media #{$size_1000} {
        article {
            margin-top: -10%;
        }
    }
    @media #{$size_700} {
        article {
            margin-top: -5%;
            padding-top: 80px;
            .num {
                top: -50px;
                width: 100px;
                height: 100px;
                background-size: 12px;
                background-position: 50% 80%;
                span {
                    @include fontsize(26);
                    padding-top: 3px;
                    i {
                        @include fontsize(16);
                    }
                }
            }
        }
    }
    @media #{$size_400} {
        article {
            margin-top: -0%;
            padding-top: 60px;
            .num {
                top: -45px;
                width: 90px;
                height: 90px;
                background-size: 8px;
                background-position: 50% 77%;
                span {
                    @include fontsize(22);
                    padding-top: 6px;
                    i {
                        @include fontsize(15);
                    }
                }
            }
            header {
                h2 {
                    line-height: 1.6;
                }
            }
        }
    }
}

.qual_main_bk {
    img {
        width: 100%;
        height: auto;
    }
}

.qual_box1 {
    padding-bottom: 193px;
    .qual_main {
        article {
            header {
                h2 {
                    padding-left: 10px;
                    padding-right: 10px;
                    box-sizing: border-box;
                }
            }
            .content {
                p {
                    @include NotoSansCJKjp-Regular;
                    line-height: 1.8;
                }
                h3 {
                    @include YuMincho-36p-Kana;
                }
                .qual_box1_p1 {
                    padding-bottom: 46px;

                }
                .qual_box1_p2 {
                    padding-bottom: 40px;
                }
                .qual_box1_div1 {
                    display: flex;
                    padding-bottom: 42px;
                    div {
                        width: 40%;
                        margin-right: 4.1935483871%;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                    p {
                        width: 55.8064516129%;
                    }
                }
                .qual_box1_p3 {
                    padding-bottom: 20px;
                }
                .qual_box1_div2 {
                    padding-bottom: 63px;
                }

            }
        }
    }
    @media #{$size_700} {
        padding-bottom: 140px;
    }
    @media #{$size_500} {
        padding-bottom: 100px;
        .qual_main {
            article {
                .content {
                    .qual_box1_p1 {
                        padding-bottom: 36px;

                    }
                    .qual_box1_p2 {
                        padding-bottom: 30px;
                    }
                    .qual_box1_div1 {
                        display: block;
                        padding-bottom: 32px;
                        div {
                            width: 100%;
                            margin-right: 0%;
                            padding-bottom: 15px;
                        }
                        p {
                            width: 100%;
                        }
                    }
                    .qual_box1_p3 {
                        padding-bottom: 15px;
                    }
                    .qual_box1_div2 {
                        padding-bottom: 53px;
                    }

                }
            }
        }
    }

}

.qual_box2 {
    padding-bottom: 176px;
    .qual_main {
        article {
            header {
                h2 {
                    padding-left: 10px;
                    padding-right: 10px;
                    box-sizing: border-box;
                }
            }
            .content {
                p {
                    @include NotoSansCJKjp-Regular;
                    line-height: 1.8;
                    padding-bottom: 40px;
                }
                .qual_main_div {
                    padding-bottom: 80px;
                }
            }
        }
    }
    @media #{$size_700} {
        padding-bottom: 140px;
    }
    @media #{$size_500} {
        padding-bottom: 100px;
        .qual_main {
            article {
                .content {
                    p {
                        padding-bottom: 30px;
                    }
                    .qual_main_div {
                        padding-bottom: 53px;
                    }
                }
            }
        }
    }
}

.qual_box3 {
    padding-bottom: 120px;
    @media #{$size_700} {
        padding-bottom: 80px;
    }
    @media #{$size_500} {
        padding-bottom: 60px;
    }
}


.qual_main_slider {
    .qual_slider_li_sub {
        img {
            width: 100%;
            height: auto;
        }
    }
    .slick-dots {
        bottom: 20px;
        li {
            width: 12px;
            height: 12px;
            margin: 0 5px;
            button {
                background-color: #fff;
                border-radius: 50%;
                width: 100%;
                height: 100%;
                padding: 0px;
                &::before {
                    display: none;

                }
            }
        }
        .slick-active {
            button {
                background-color: #000;
            }
        }
    }
}


.qual_box4 {
    color: #000;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    .qual_subtitle {
        margin-bottom: 38px;
    }
    h2 {
        @include YuMincho-36p-Kana;
        padding-bottom: 44px;
    }
    @media #{$size_500} {
        .qual_subtitle {
            margin-bottom: 30px;
        }
        h2 {
            @include YuMincho-36p-Kana;
            padding-bottom: 34px;
        }
    }
}

.qual_box5 {
    margin-bottom: 95px;
    &::before {
        content: "";
        display: block;
        width: 100%;
        height: 340px;
        background-color: #fff;
    }
    .qual_box5_slider {
        margin-top: -340px;

        .slick-list {
            padding-top: 60px;
        }
        .qual_slider_li {
            outline: none;
            padding-left: 30px;
            padding-right: 30px;
            position: relative;
            box-sizing: border-box;
            i {
                display: block;
                img {
                    width: 100%;
                    height: auto;
                }
            }
            div {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: auto;
                p {
                    position: relative;
                    height: 300px;
                    span {
                        background-color: #000;
                        color: #fff;
                        position: absolute;
                        top: -35px;
                        right: 65px;
                        -ms-writing-mode: tb-rl;
                        -webkit-writing-mode: vertical-rl;
                        writing-mode: vertical-rl;
                        @include YuMincho;
                        @include fontsize(26);
                        display: inline-block;
                        padding: 10px 10px;
                        word-wrap: normal;
                        font-weight: 500;
                        @include hack() {
                            padding: 10px 10px 10px 3px;
                        }
                    }
                }
            }
        }
    }
    @media #{$size_2000} {
        &::before {
            height: 500px;
        }
        .qual_box5_slider {
            margin-top: -540px;
            .qual_slider_li {
                padding-top: 80px;
                div {
                    p {
                        span {
                            top: 45px;
                            @include fontsize(30);
                        }
                    }
                }
            }
        }
    }
    @media #{$size_1800} {
        &::before {
            height: 340px;
        }
        .qual_box5_slider {
            margin-top: -360px;
            .qual_slider_li {
                padding-top: 60px;
                div {
                    p {
                        span {
                            top: 25px;
                            @include fontsize(26);
                        }
                    }
                }
            }
        }
    }
    @media #{$size_1300} {
        &::before {
            height: 240px;
        }
        .qual_box5_slider {
            margin-top: -270px;
            .qual_slider_li {
                padding-top: 40px;
                div {
                    p {
                        span {
                            top: 5px;
                            @include fontsize(25);
                        }
                    }
                }
            }
        }
    }
    @media #{$size_1000} {
        &::before {
            height: 220px;
        }
        .qual_box5_slider {
            margin-top: -260px;
            .qual_slider_li {
                padding-left: 10px;
                padding-right: 10px;
                div {
                    p {
                        span {
                            top: -5px;
                            right: 35px;
                            @include fontsize(20);
                        }
                    }
                }
            }
        }
    }
    @media #{$size_600} {
        margin-bottom: 50px;
        &::before {
            height: 180px;
        }
        .qual_box5_slider {
            margin-top: -220px;
            .qual_slider_li {
                padding-top: 20px;
                div {
                    p {
                        span {
                            @include fontsize(18);
                        }
                    }
                }
            }
        }
    }
}
// Tel.
.qual_box6 {
    padding-bottom: 220px;
    .title {
        padding-bottom: 60px;
        h2 {
            letter-spacing: -1px;
            @include YuMincho-36p-Kana;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 20px;
            box-sizing: border-box;
            font-weight: 500;
        }
        .qual_box6_p1 {
            @include YuMincho-36p-Kana;
            @include fontsize(60);
            padding-bottom: 20px;
            span {
                font-weight: 500;
                display: inline-block;
                line-height: 1.2;
                width: 80px;
                height: 80px;
                border-radius: 50%;
                color: #fff;
                background-color: #000;
                &:nth-child(1) {
                    transform: translateX(20px);
                }
                &:nth-child(2) {
                    transform: translateX(8px);

                }
                &:nth-child(3) {
                    transform: translateX(-8px);

                }
                &:nth-child(4) {
                    transform: translateX(-20px);

                }
                @include hack() {
                    line-height: 1.6;
                }
            }
        }
        .qual_box6_div1 {
            @include NotoSansCJKjp-Regular;
            line-height: 2.0;
            padding-bottom: 45px;

        }
        .qual_box6_div2 {
            h3 {
                @include YuMincho-36p-Kana;
                padding-bottom: 10px;
            }
            .qual_box6_p2 {
                padding-bottom: 8px;
                a {
                    display: inline-block;
                }
                span {
                    @include NotoSansCJKjp-Bold;
                    position: relative;
                    display: inline-block;
                    padding-left: 72px;
                    &::before {
                        position: absolute;
                        top: 0;
                        left: 0;
                        content: "Tel. ";
                        display: inline-block;
                    }
                }
            }
            .qual_box6_p3 {
                @include NotoSansCJKjp-Regular;
            }
        }
    }
    .main {
        article {
            display: flex;
            flex-direction: row-reverse;
            margin-bottom: 32px;
            .img {
                width: 45.8%;
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .content {
                width: 50%;
                margin-right: 4.2%;
                display: flex;
                justify-content: center;
                align-items: center;
                header {
                    width: 100%;
                    display: block;
                    text-align: left;
                    h3 {
                        @include fontsize(44);
                        @include YuMincho-36p-Kana;
                        font-weight: 500;
                        padding: 20px 10px 20px 25px;
                        border-top: 1px solid #000;
                        border-bottom: 1px solid #000;
                        margin-bottom: 18px;
                        letter-spacing: -3px;
                    }
                    p {
                        @include NotoSansCJKjp-Regular;

                        line-height: 2.0;
                        padding-left: 30px;
                        padding-right: 30px;
                        letter-spacing: -1px;
                    }
                }
            }
            &:nth-child(2n) {
                flex-direction: row;
                .content {
                    margin-left: 4.2%;
                }
            }
            &:last-child {
                margin-bottom: 0px;
            }
        }

    }
    @media #{$size_800} {
        padding-bottom: 160px;
        .main {
            article {
                .content {
                    header {
                        h3 {
                            @include fontsize(30);
                        }
                    }
                }
            }
        }
    }
    @media #{$size_700} {
        .title {
            .qual_box6_div2 {
                .qual_box6_p2 {
                    span {
                        padding-left: 58px;
                    }
                }
            }
        }
    }
    @media #{$size_600} {
        padding-bottom: 120px;
        .title {
            padding-bottom: 40px;
            .qual_box6_p1 {
                @include fontsize(44);
                span {
                    width: 60px;
                    height: 60px;
                    &:nth-child(1) {
                        transform: translateX(12px);
                    }
                    &:nth-child(2) {
                        transform: translateX(5px);

                    }
                    &:nth-child(3) {
                        transform: translateX(-5px);

                    }
                    &:nth-child(4) {
                        transform: translateX(-12px);

                    }
                }
            }
            .qual_box6_div1 {
                padding-left: 20px;
                padding-right: 20px;
                box-sizing: border-box;
                text-align: left;
                padding-bottom: 30px;
            }
        }
        .main {
            article {
                display: block;
                .img {
                    max-width: 320px;
                    margin-left: auto;
                    margin-right: auto;
                    width: 100%;
                    padding-bottom: 20px;
                }
                .content {
                    width: 100%;
                    margin-right: 0% !important;
                    margin-left: 0% !important;
                    display: block;
                    header {
                        h3 {
                            @include YuMincho-36p-Kana;
                            font-weight: 500;
                            padding: 15px 10px 15px 20px;
                            margin-bottom: 14px;
                        }
                        p {
                            padding-left: 20px;
                            padding-right: 20px;
                        }
                    }
                }
                &:last-child {
                    margin-bottom: 0px;
                }
            }

        }
    }
    @media #{$size_400} {
        padding-bottom: 80px;
        .main {
            article {
                .content {
                    header {
                        h3 {
                            @include fontsize(24);
                        }
                    }
                }
            }
        }
    }
}
