@charset "UTF-8";

.news_title {
    padding-top: 60px;
    padding-bottom: 117px;
    @media #{$size_800} {
        padding-top: 40px;
        padding-bottom: 60px;
    }
}

.newslist_main {
    padding-bottom: 290px;
    article {
        margin-bottom: 100px;
        border-bottom: 1px solid #000;
        &:last-child {
            margin-bottom: 94px;
        }
        .news_a {
            text-align: left;
            display: block;
            color: #000;
            header {
                .cate a,.cate span{
                    @include NotoSansCJKjp-Regular;
                    padding: 5px 16px 5px 16px;
                    background-color: #fff;
                    margin-bottom: 16px;
                    display: inline-block;
                }
                .cate a{
                  &:hover{
                    background: rgba(255,255,255,0.7);
                  }
                }
                .titl {
                    @include NotoSansCJKjp-Medium;
                    padding-bottom: 18px;
                    line-height: 1.6;
                    a{
                      &:hover{
                        text-decoration: underline;
                      }
                    }
                }
                .date {
                    @include NotoSansCJKjp-Regular;
                    padding-bottom: 23px;
                }

            }
            .content {
                padding-bottom: 30px;
                p {
                    padding-bottom: 54px;
                    line-height: 1.6;
                    @include NotoSansCJKjp-Regular;
                    &:last-child {
                      padding-bottom: 20px;
                    }
                }
                img {
                    max-width: 100%;
                    height: auto;
                }
                .eyecatch{
                    margin-bottom: 25px;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                a.more{
                  &:hover{
                    text-decoration: underline;
                  }
                }
            }
        }
        aside {
            padding-bottom: 92px;
            padding-bottom: 62px;
        }

    }
    @media #{$size_800} {
        padding-bottom: 150px;
        article {
            margin-bottom: 60px;
            &:last-child {
                margin-bottom: 54px;
            }
            .news_a {
                header {
                    .cate a,.cate span{
                        padding: 4px 14px 4px 14px;
                        margin-bottom: 14px;
                    }
                    .titl {
                        padding-bottom: 16px;
                    }
                    .date {
                        padding-bottom: 26px;
                    }

                }
                .content {
                    padding-bottom: 20px;
                    p {
                        padding-bottom: 34px;
                    }
                }
            }

            aside {
                padding-bottom: 62px;
            }

        }
    }
    @media #{$size_400} {
        padding-bottom: 90px;
    }
}

.newsdetail_box1 {
    padding-bottom: 290px;
    article {
        text-align: left;
        color: #000;
        header {
            .cate span{
                @include NotoSansCJKjp-Regular;
                padding: 5px 16px 5px 16px;
                background-color: #fff;
                margin-bottom: 16px;
                display: inline-block;
            }
            .titl {
                @include NotoSansCJKjp-Medium;
                padding-bottom: 18px;
                line-height: 1.6;
            }
            .date {
                @include NotoSansCJKjp-Regular;
                padding-bottom: 23px;
            }
        }
        .content {
            padding-bottom: 40px;
            p {
                padding-bottom: 50px;
                line-height: 1.6;
                @include NotoSansCJKjp-Regular;
            }
            img{
              max-width: 100%;
              height: auto;
            }
            .eyecatch {
              margin-bottom: 45px;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
        aside {
            padding-bottom: 80px;
        }
    }
    @media #{$size_800} {
        padding-bottom: 150px;
        article {
            header {
                .cate span {
                    padding: 4px 14px 4px 14px;
                    margin-bottom: 14px;
                }
                .titl {
                    padding-bottom: 16px;
                }
                .date {
                    padding-bottom: 26px;
                }

            }
            .content {
                padding-bottom: 20px;
                p {
                    padding-bottom: 30px;
                }
            }

            aside {
                padding-bottom: 60px;
            }

        }
    }
    .blogTop{
      @include NotoSansCJKjp-Regular;
      display: inline-block;
      a{
        box-sizing: border-box;
        padding: 0 75px;
        height: 50px;
        border: 1px solid #000;
        background-color: #fff;
        color: #000000;
        transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover{
          color: #fff;
          background-color: #000;
          text-decoration: none;
        }
      }
    }
}


.parts_pagination {
    justify-content: center;
    li {
        &:first-child {
            margin-left: 20px;
        }
        &:last-child {
            margin-left: 20px;
        }
        @media #{$size_1000} {
          margin: 0 10px !important;
        }
        @media #{$size_400} {
          margin: 0 6px !important;
        }
    }
}

.parts_main_side .right{
  @media #{$size_800} {
    padding-bottom: 50px;
  }
}
.categoryListPC{
  @media #{$size_800} {
    display: none;
  }
}
.categoryListSP{
  display: none;
  label{
    position: relative;
    width:100%;
    height:auto;
    display: inline-block;
    &::after{
      display: block;
      content: "";
      position: absolute;
      top: 50%;
      right:20px;
      width:10px;
      height:10px;
      margin:-7px 0 0 0;
      border-right:2px solid #333333;
      border-top:2px solid #333333;
      -webkit-transform: rotate(135deg);
      transform:rotate(135deg);
      cursor:pointer;
    }
    select{
      -webkit-appearance: button;
      -moz-appearance: button;
      appearance: button;
      width:100%;
      height:45px;
      margin:0;
      padding: 0;
      padding:0 20px 0 10px;
      line-height:45px;
      vertical-align: middle;
      position:relative;
      background-color: transparent;
      border: 1px solid #333333;
      border-radius: 0;
      background: rgba(255,255,255,0.4);
      cursor:pointer;
      @include NotoSansCJKjp-Regular;
      @include fontsize(16);
      color:#333333;
      option{
        background-color: transparent;
        transform: scale(0.875);
        color:#333333;
      }
      &::-ms-expand {
        display: none;
        background-color: transparent;
      }
      background: rgba(255,255,255,0.4);
      @-moz-document url-prefix() {
        background:none;
        background-color: transparent;
        background: rgba(255,255,255,0.4);
      }
    }
    _:-ms-lang(x)::-ms-backdrop, select {
      padding-top: 8px;
    }
  }
  @media #{$size_800} {
    display: block;
  }
  select::-ms-expand{
    display: none;
  }
}
