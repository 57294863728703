@charset "utf-8";

body {
    background-image: url("../images/common/base_bk.jpg");
    background-size: 280px;
    background-repeat: repeat;
}

.pr_65_55_40 {
    @include fontsize(65);
    @media #{$size_700} {
      @include fontsize(40);
    }
    @media #{$size_400} {
      @include fontsize(40);
    }
}

.pr_44_34_28 {
    @include fontsize(44);
    @media #{$size_700} {
      @include fontsize(34);
    }
    @media #{$size_400} {
      @include fontsize(28);
    }
}


.pr_44_30_24 {
    @include fontsize(44);
    @media #{$size_700} {
      @include fontsize(30);
    }
    @media #{$size_400} {
      @include fontsize(24);
    }
}

.pr_40_30_24 {
    @include fontsize(40);
    @media #{$size_1000} {
        @include fontsize(32);
    }
    @media #{$size_700} {
      @include fontsize(30);
    }
    @media #{$size_400} {
      @include fontsize(24);
    }
}

.pr_35_30_28 {
    @include fontsize(35);
    @media #{$size_700} {
      @include fontsize(30);
    }
    @media #{$size_400} {
      @include fontsize(28);
    }
}

.pr_30_26_23 {
    @include fontsize(30);
    @media #{$size_700} {
      @include fontsize(26);
    }
    @media #{$size_400} {
      @include fontsize(23);
    }
}


.pr_28_25_23 {
    @include fontsize(28);
    @media #{$size_700} {
      @include fontsize(25);
    }
    @media #{$size_400} {
      @include fontsize(23);
    }
}

.pr_26_24_22 {
    @include fontsize(26);
    @media #{$size_700} {
      @include fontsize(24);
    }
    @media #{$size_400} {
      @include fontsize(22);
    }
}


.pr_25_23_22 {
    @include fontsize(24);
    @media #{$size_700} {
      @include fontsize(22);
    }
    @media #{$size_400} {
      @include fontsize(20);
    }
}

.pr_24_22_20 {
    @include fontsize(24);
    @media #{$size_700} {
      @include fontsize(22);
    }
    @media #{$size_400} {
      @include fontsize(20);
    }
}

.pr_23_21_21 {
    @include fontsize(23);
    @media #{$size_700} {
      @include fontsize(21);
    }
}

.pr_22_20_18 {
    @include fontsize(22);
    @media #{$size_700} {
      @include fontsize(20);
    }
    @media #{$size_400} {
      @include fontsize(18);
    }
}

.pr_18_17_16 {
    @include fontsize(18);
    @media #{$size_700} {
      @include fontsize(17);
    }
    @media #{$size_400} {
      @include fontsize(16);
    }
}

.pr_16_16_15 {
    @include fontsize(15);
    @media #{$size_400} {
      @include fontsize(14);
    }
}
.pr_15_15_14 {
    @include fontsize(15);
    @media #{$size_400} {
      @include fontsize(14);
    }
}

.max1000padding20 {
    max-width: 1040px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
}

.contents-wapper {
  max-width: 1600px;
  margin: 0 auto;
}

.display_320 {
  display: none;
  @media #{$size_320} {
    display: block;
  }
}
.display_374 {
  display: none;
  @media #{$size_374} {
    display: block;
  }
}
.display_400 {
  display: none;
  @media #{$size_400} {
    display: block;
  }
}
.display_500 {
  display: none;
  @media #{$size_500} {
    display: block;
  }
}
.display_550 {
  display: none;
  @media #{$size_550} {
    display: block;
  }
}

.display_700 {
  display: none;
  @media #{$size_700} {
    display: block;
  }
}
.display_800 {
  display: none;
  @media #{$size_800} {
    display: block;
  }
}

.display_900 {
  display: none;
  @media #{$size_900} {
    display: block;
  }
}

.display_1000 {
  display: none;
  @media #{$size_1000} {
    display: block;
  }
}
.display_1100 {
  display: none;
  @media #{$size_1100} {
    display: block;
  }
}

.display_1200 {
  display: none;
  @media #{$size_1200} {
    display: block;
  }
}


.display_none_600 {
  @media #{$size_600} {
    display: none;
  }
}

.display_none_1200 {
  @media #{$size_1200} {
    display: none;
  }
}

.font_YuMincho-36p-Kana {
  @include YuMincho-36p-Kana;
  font-weight: normal;
}

.font_Num {
  @include Num;
  font-weight: normal;
}

.vertical-writing {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;

}

//linkbox
.linkBox {
  display: block;
  flex-direction: column;
  margin: 0 auto;
  max-width: 361px;
  height: 70px;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  @media #{$size_400} {
    display: inline-flex;
    flex-direction: column;
    padding: 10px 30px;
  }

  b {
    display: inline-block;
    @include fontsize(22);
    @media #{$size_400} {
      @include fontsize(16);
    }
  }

  span {
    display: inline-block;
    @include fontsize(28);
    @media #{$size_400} {
      @include fontsize(18);
    }
  }

  &::before {
    display: block;
    content: '';
    height: 100%;
    width: 10px;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    border-bottom: 1px solid #000;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 1s;
  }

  &::after {
    display: block;
    content: '';
    height: 100%;
    width: 10px;
    border-top: 1px solid #000;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    position: absolute;
    top: 0;
    right: 0;
    transition: all 1s;
  }
}

//linkbox ホバーエフェクト
.linkBox:hover {

  b, span {
    color: #fff;
  }

  &::before, &::after {
    height: 105%;
    width: 100%;
    // background-color: #a67f48;
    background-color: #000;
    border-color: transparent;
    transition: all 1s;
    z-index: -1;
  }
}



.fc_title-part-1 {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .fc_secTitle-01 {
    @include YuMincho-36p-Kana;
    @include fontsize(60);
    color: #000;
    line-height: 1.5;
    letter-spacing: 2px;
    @media #{$size_1200} {
      @include fontsize(48);
    }
    @media #{$size_1000} {
      @include fontsize(40);
    }
    @media #{$size_800} {
      @include fontsize(38);
    }
    @media #{$size_600} {
      @include fontsize(32);
    }
    @media #{$size_400} {
      @include fontsize(28);
    }
  }

  .fc_secSubTitle-01 {
    display: block;
    @include YuMincho-36p-Kana;
    @include fontsize(20);
    color: #000;
    line-height: 1.8;
    letter-spacing: 2px;
    @media #{$size_800} {
      @include fontsize(18);
    }
    @media #{$size_600} {
      @include fontsize(16);
    }
    @media #{$size_400} {
      @include fontsize(14);
    }
  }

}

.fc_detailText {
  @include NotoSansCJKjp-Regular;
  @include fontsize(15);
  line-height: 1.8;
  font-weight: normal;
  color: #000;
  @media #{$size_600} {
    @include fontsize(14);
    line-height: 1.6;
  }
}


.fc_title-part-2 {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .fc_secTitle-02 {
    @include YuMincho-36p-Kana;
    @include fontsize(44);
    color: #000;
    @media #{$size_1200} {
      @include fontsize(42);
    }
    @media #{$size_1000} {
      @include fontsize(38);
    }
    @media #{$size_800} {
      @include fontsize(32);
    }
    @media #{$size_600} {
      @include fontsize(24);
    }
    @media #{$size_400} {
      @include fontsize(20);
    }
  }

  .fc_secSubTitle-02 {
    display: block;
    @include YuMincho-36p-Kana;
    @include fontsize(20);
    color: #000;
    line-height: 2.2;
    letter-spacing: 1px;
    @media #{$size_800} {
      @include fontsize(18);
      line-height: 1.8;
    }
    @media #{$size_600} {
      @include fontsize(16);
    }
    @media #{$size_400} {
      @include fontsize(14);
    }
  }
}

.fc_section-preface {
  @include NotoSansCJKjp-Regular;
  @include fontsize(15);
  color: #000;
  font-weight: normal;
  line-height: 1.6;
  @media #{$size_600} {
    @include fontsize(14);
    line-height: 1.5;
    text-align: left;
  }
  @media #{$size_400} {
    @include fontsize(14);
  }
}

.cont_title-part-1 {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .cont_top_titleArea {
    padding-bottom: 60px;
  }

  .cont_secTitle-01 {
    @include YuMincho-36p-Kana;
    @include fontsize(60);
    color: #000;
    line-height: 1.5;
    letter-spacing: 2px;
    @media #{$size_1200} {
      @include fontsize(48);
    }
    @media #{$size_1000} {
      @include fontsize(40);
    }
    @media #{$size_800} {
      @include fontsize(38);
    }
    @media #{$size_600} {
      @include fontsize(32);
    }
    @media #{$size_400} {
      @include fontsize(28);
    }
  }

  .cont_secSubTitle-01 {
    display: block;
    @include YuMincho-36p-Kana;
    @include fontsize(20);
    color: #000;
    line-height: 1.8;
    letter-spacing: 2px;
    @media #{$size_800} {
      @include fontsize(18);
    }
    @media #{$size_600} {
      @include fontsize(16);
    }
    @media #{$size_400} {
      @include fontsize(14);
    }
  }
}

.cont_section-preface {
  @include NotoSansCJKjp-Medium;
  @include fontsize(15);
  color: #000;
  font-weight: normal;
  line-height: 1.8;
  @media #{$size_600} {
    @include fontsize(14);
    text-align: left;
  }
  @media #{$size_400} {
    @include fontsize(14);
  }
}

.part_form_container {
  margin: auto;
  box-sizing: border-box;
  max-width: 785px;
  padding-left: 20px;
  padding-right: 20px;
}

.part_must {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 41px;
  height: 23px;
  border-radius: 4px;
  background-color: #ea440a;
  @include fontsize(15);
  color: #fff;
}


.parts_main_side {
    display: flex;
    flex-direction: row-reverse;
    .center {
        flex: 1;
    }
    .right {
        width: 250px;
        margin-left: 9%;
    }
    @media #{$size_800} {
        display: block;
        .center {
            width: 100%;
        }
        .right {
            width: 100%;
            margin-left: 0%;
            padding-bottom: 80px;
        }
    }
}

.parts_pagination {
    @include fontsize(14);
    @include NotoSansCJKjp-Medium;
    display: flex;
    position: relative;
    li {
        margin-left: 20px;
        &:first-child, &:last-child {
            margin-left: 0px;
        }
        span {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            background-color: #000;
            width: 50px;
            height: 50px;
            box-sizing: border-box;
            border: 1px solid #000;
        }
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #000;
            background-color: #fff;
            width: 50px;
            height: 50px;
            box-sizing: border-box;
            border: 1px solid #000;
            @include transition0-3;
            &:hover {
                @include transition0-3;
                color: #fff;
                background-color: #000;
            }
        }
        .link_after {
            position: absolute;
            top: 0;
            right: 0;
            background-image: url('../images/news/arrow1.png');
            background-size: 8px;
            background-repeat: no-repeat;
            background-position: center;
            &:hover {
                @include transition0-3;
                background-image: url('../images/news/arrow2.png');
            }
        }
    }
    @media #{$size_1000} {
        justify-content: space-between;
        li {
            margin-left: 0px !important;
            span {
                width: 40px;
                height: 40px;
            }
            a {
                width: 40px;
                height: 40px;
            }
            .link_after {
                position: static;
            }
        }
    }
    @media #{$size_500} {
        li {
            span {
                width: 35px;
                height: 35px;
            }
            a {
                width: 35px;
                height: 35px;
            }
        }
    }
    @media #{$size_400} {
        li {
            span {
                width: 30px;
                height: 30px;
            }
            a {
                width: 30px;
                height: 30px;
            }
        }
    }
    @media #{$size_374} {
        li {
            span {
                width: 27px;
                height: 27px;
            }
            a {
                width: 27px;
                height: 27px;
            }
        }
    }
}

.parts_side {
    text-align: left;
    h2 {
        @include fontsize(20);
        @include YuMincho;
        font-weight: 700;
        padding-top: 25px;
        padding-bottom: 25px;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        padding-left: 4px;
        padding-right: 4px;
        box-sizing: border-box;
        color: #000;
    }
    ul {
        li {
            background-image: url('../images/news/border.png');
            background-size: 4px;
            background-repeat: repeat-x;
            background-position: 50% 100%;
            a,span {
                @include NotoSansCJKjp-Regular;
                color: #000;
                display: block;
                padding: 18px 4px 18px 4px;
                @include transition0-3;
            }
            a{
              &:hover {
                  @include transition0-3;
                  text-decoration: underline;
              }
            }
        }
    }

    @media #{$size_800} {
        h2 {
            @include fontsize(18);
            padding-top: 20px;
            padding-bottom: 20px;
        }
        ul {
            li {
                a,span {
                    padding: 14px 4px 14px 4px;
                }
            }
        }
    }
}

.parts_share1 {
    text-align: left;
    display: flex;

    .title {
        span {
            display: flex;
            width: 70px;
            height: 30px;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            position: relative;
            margin-right: 27px;
            &::before {
                display: inline-block;
                content: "";
                position: absolute;
                top: 0;
                right: -14px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 15px 0 15px 14px;
                border-color: transparent transparent transparent #ffffff;
                line-height: 0px;
                _border-color: #000000 #000000 #000000 #ffffff;
                _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
            }
            i {
                display: inline-block;
                color: #000;
                @include YuMincho;
            }
        }
    }
    a {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        @include transition0-3;
        i {
            @include fontsize(18);
            color: #fff;
            display: inline-block;
        }
        &:hover {
            @include transition0-3;
            background-color: #000;
        }
    }
    .twitter {
        background-color: #73cbef;
        margin-right: 10px;
    }
    .facebook {
        background-color: #4f79bc;
    }
}

.parts_detail_nav {
    width: 208px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    li {
        a {
            box-sizing: border-box;
            display: block;
            width: 50px;
            height: 50px;
            border: 1px solid #000;
            background-color: #fff;
            background-repeat: no-repeat;
            @include transition0-3;
            &:hover {
                @include transition0-3;
                background-color: #000;
            }
        }
        &:nth-child(1) {
            a {
                background-image: url('../images/news/nav1.png');
                background-size: 37px;
                background-position: right 16px;
                &:hover {
                    @include transition0-3;
                    background-image: url('../images/news/nav1_2.png');
                }
            }

        }
        &:nth-child(2) {
            a {
                background-image: url('../images/news/nav2.png');
                background-size: 14px;
                background-position: 50% 50%;
                &:hover {
                    @include transition0-3;
                    background-image: url('../images/news/nav2_2.png');
                }
            }

        }
        &:nth-child(3) {
            a {
                background-image: url('../images/news/nav3.png');
                background-size: 37px;
                background-position: left 16px;
                &:hover {
                    @include transition0-3;
                    background-image: url('../images/news/nav3_2.png');
                }
            }

        }
    }
}

.pr_over_hidden {
    overflow: hidden;
}

.pr_vertical_text1 {
    width: 100%;
    height: 430px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 4;
    span {
        position: absolute;
        -ms-writing-mode: tb-rl;
        -webkit-writing-mode: vertical-rl;
        writing-mode: vertical-rl;
        @include YuMincho;
        @include fontsize(26);
        font-weight: 500;
        color: #fff;
        display: inline-block;
        background-color: #000;
        padding: 15px 2px;
        word-wrap: normal;
        &:nth-child(1) {
            top: 0;
            left: 50px;
        }
        &:nth-child(2) {
            top: 0;
            left: 0px;
        }
    }
    @include hack() {
        span {
            padding: 15px 6px 15px 0px;
        }
    }
    @media #{$size_700} {
        span {
            @include fontsize(23);
            &:nth-child(1) {
                top: 0;
                left: auto;
                left: initial;
                right: 0;
            }
            &:nth-child(2) {
                top: 0;
                left: 0;
            }
        }
    }
}



.parts_letter-spacing_-005 {
  padding-left: 7px !important;
  letter-spacing: -0.02em;
}

.parts_shop-IMG_none {
  display: block;
  width: 100%;
  height: 245px;
  background-color: #b5b5b5;
  @media #{$size_1000} {
    height: 320px;
  }
  @media #{$size_800} {
    height: 280px;
  }
  @media #{$size_800} {
    height: 400px;
  }
  @media #{$size_500} {
    height: 310px;
  }
  @media #{$size_400} {
    height: 240px;
    width: 280px;
  }

  img {
    display: block;
    width: 100%;
  }
}


.parts_shop_comment-bln {
  position: relative;

  &::before {
    display: block;
    content: 'NEW';
    @include NotoSansCJKjp-Regular;
    @include fontsize(24);
    line-height: 1;
    color: #fff;
    position: absolute;
    top: 0;
    right: 25px;
    z-index: 10;
  }

  &::after {
    display: block;
    content: '';
    background-image: url('../images/shop/shop_shopList_comment-bln.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 72px;
    height: 82px;
    position: absolute;
    top: -24px;
    right: 15px;
  }
}
