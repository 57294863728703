@charset "UTF-8";

section.cont_top {
    @include NotoSansCJKjp-Regular;
    padding: 60px 20px;

    div.cont_top_titleArea {
        i {
            @include fontsize(24);
            display: block;
            padding-bottom: 60px;
            @media #{$size_400} {
                padding-bottom: 30px;
            }
        }
        .cont_section-preface {
            @include NotoSansCJKjp-Regular;
        }
    }
    @media #{$size_800} {
        padding: 40px 20px 100px;
    }
    @media #{$size_600} {
        padding: 40px 20px 80px;
    }
    @media #{$size_400} {
        padding: 40px 20px 60px;
    }
}

section.cont_form {
    @include NotoSansCJKjp-Regular;

    form {
        .cont_form_back {
            background-image: url("../images/common/unnamed.jpg");
            background-size: 280px;
            background-repeat: repeat;
            padding: 60px 20px 89px;

            .cont_section-preface {
                @include fontsize(20);
                padding-bottom: 100px;
            }

            .cont_form_inner {
                margin: auto;
                @include fontsize(15);
                max-width: 784px;

                ul {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 0 auto;
                    padding-top: 53px;
                    text-align: left;

                    .cont_must {
                        width: 7.14285714286%;
                        text-align: left;
                    }

                    .cont_th {
                        width: 26.9132653061%;
                    }

                    .cont_input {
                        display: flex;
                        align-items: center;
                        width: 66.4540816327%;
                        height: 51px;
                        @media #{$size_800} {
                            height: 40px;
                        }

                        input[type="text"] {
                            box-sizing: border-box;
                            border-radius: 5px;
                            border: 1px solid #666666;
                            width: 100%;
                            height: 100%;
                            @include fontsize(15);
                            padding-left: 26px;
                            @media #{$size_800} {
                                padding-left: 15px;
                            }
                        }

                        input[type="radio"] {
                            display: none;
                        }

                        input[type="radio"] + label {
                            cursor: pointer;
                            position: relative;
                            padding-left: 25px;
                            margin-right: 70px;

                            &::before {
                                content: "";
                                display: block;
                                position: absolute;
                                top: 1px;
                                left: 0;
                                width: 15px;
                                height: 15px;
                                border: 1px solid #999;
                                border-radius: 50%;
                                background-color: #fff;
                            }
                            @media all and (-ms-high-contrast: none) {
                                padding-top: 3px;
                            }
                        }

                        input[type="radio"]:checked + label {
                            color: #009a9a;

                            &::after {
                                content: "";
                                display: block;
                                position: absolute;
                                top: 4px;
                                left: 3px;
                                width: 11px;
                                height: 11px;
                                border-radius: 50%;
                                background-color: #009a9a;
                            }
                        }
                    }

                    .cont_select {
                        position: relative;

                        select {
                            box-sizing: border-box;
                            display: flex;
                            align-items: center;
                            border-radius: 5px;
                            padding-left: 26px;
                            border: 1px solid #666666;
                            width: 100%;
                            height: 100%;
                            @include fontsize(15);
                            background-color: #fff;
                            appearance: none;
                            @media #{$size_800} {
                                padding-left: 15px;
                            }

                            &::-ms-expand {
                                display: none;
                            }
                        }

                        &::after {
                            content: '';
                            position: absolute;
                            top: 13px;
                            right: 23px;
                            margin: auto;
                            width: 14px;
                            height: 14px;
                            border: 0;
                            border-bottom: solid 2px #5d5d5d;
                            border-right: solid 2px #5d5d5d;
                            transform: rotate(45deg);
                        }
                        @media #{$size_800} {
                            &::after {
                                top: 12px;
                                right: 20px;
                                width: 10px;
                                height: 10px;
                                border-bottom: solid 1px #5d5d5d;
                                border-right: solid 1px #5d5d5d;
                            }
                        }
                    }

                    .cont_name {
                        input[type="text"] {
                            width: 37.8119001919%;
                            margin-right: 13px;

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }

                    &:first-child {
                        padding-top: 0;
                    }
                }

                .cont_ul_textarea {
                    align-items: flex-start;

                    .cont_textarea {
                        height: 163px;
                        overflow-y: auto;
                        align-self:stretch;
                        vertical-align: top;

                        textarea {
                            box-sizing: border-box;
                            width: 100%;
                            height: 100%;
                            border: 1px solid #666666;
                            @include fontsize(15);
                            padding: 15px 26px;
                            border-radius: 5px;
                            overflow: hidden;
                            @media #{$size_800} {
                                padding: 10px 15px;
                            }
                        }
                    }
                }


                p.error{
                  padding-left: 33.545918368%;
                }
            }
        }

        .cont_bottom {
            @include NotoSansCJKjp-Regular;
            padding: 60px 20px 270px;

            .cont_inner {
                max-width: 661px;
                margin: auto;

                .cont_must {
                    margin-bottom: 15px;

                    span {
                        margin: auto;
                    }
                }

                .cont_section-preface {
                    @include fontsize(20);
                    padding-bottom: 35px;
                    text-align: center;

                    span {
                        margin: auto;
                    }
                }

                .cont_whitebox {
                    height: 260px;
                    overflow: scroll;
                    box-sizing: border-box;
                    border-radius: 5px;
                    border: 1px solid #666666;
                    width: 100%;
                    background-color: #fff;
                    text-align: left;
                    @include fontsize(15);
                    padding: 30px 20px;
                    margin-bottom: 31px;
                    @media #{$size_400} {
                        height: 200px;
                    }

                }

                .cont_input {

                    label {
                        margin: auto;
                        cursor: pointer;

                        input[type="checkbox"] {
                            display: none;
                        }

                        .cont_check_box {
                            padding-left: 32px;
                            position: relative;

                            &::before {
                                content: "";
                                display: block;
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 15px;
                                height: 15px;
                                border: 1px solid #999;
                                border-radius: 4px;
                                background-color: #fff;
                            }
                        }

                        input[type="checkbox"]:checked + span {
                            color: #009a9a;

                            &::after {
                                content: "";
                                display: block;
                                position: absolute;
                                top: -5px;
                                left: 5px;
                                width: 7px;
                                height: 14px;
                                transform: rotate(40deg);
                                border-bottom: 3px solid #009a9a;
                                border-right: 3px solid #009a9a;
                            }
                        }
                    }

                    .selector,
                    _:-ms-lang(x)::backdrop {
                        display: block;
                    }
                }

                .cont_submit {
                    width: 100%;
                    height: 80px;
                    margin-top: 63px;

                    input[type="submit"] {
                        @include NotoSansCJKjp-Regular;
                        cursor: pointer;
                        display: block;
                        width: 100%;
                        height: 100%;
                        border: none;
                        border-radius: 0;
                        background-color: #0a0607;
                        color: #fff;
                        border: 1px solid #0a0607;
                        box-sizing: border-box;
                        @include fontsize(24);
                        transition: 0.3s;

                        &:hover {
                            background-color: #ccc;
                            border-color: #ccc;
                            transition: 0.3s;
                        }
                    }

                    input[type="submit"][disabled] {
                        /*border: 1px solid #ccc;
                        background-color: #ccc;*/
                        cursor: default;
                    }
                }
            }
        }

        p.error{
          padding-top: 15px;
          color: #ea440a;
          font-size: 1rem;
          font-weight: bold;
          text-align: left;;
        }
    }
    @media #{$size_800} {
        form {
            .cont_form_back {
                padding: 40px 20px 100px;

                .cont_section-preface {
                    padding-bottom: 45px;
                }
            }
        }
    }
    @media #{$size_700} {
        form {
            .cont_form_back {
                .cont_form_inner {
                    ul {
                        flex-direction: column;
                        align-items: flex-start;
                        padding-top: 40px;

                        li {
                            &:first-child {
                                margin-bottom: 5px;
                                width: 100%;
                            }

                            &:nth-child(2) {
                                margin-bottom: 10px;
                                width: 100%;
                            }

                            &:last-child {
                                margin-bottom: 0;
                                width: 100%;
                            }
                        }

                        .cont_input_check {
                            height: 30px;
                        }
                    }
                    p.error{
                      padding-left: 0;
                    }
                }
            }

            .cont_bottom {
                .cont_inner {
                    .cont_submit {
                        max-width: 260px;
                        height: 50px;
                        margin: 30px auto 0;

                        input[type="submit"] {
                            @include fontsize(18);
                        }
                    }
                }
            }

            p.error{
              padding-top: 10px;
            }

        }
    }
    @media #{$size_600} {
        form {
            .cont_form_back {
                padding: 20px 20px 80px;
                .cont_section-preface {
                    text-align: left;
                }
            }

            .cont_bottom {
                padding: 60px 20px 150px;
            }
        }
    }
    @media #{$size_400} {
        form {
            .cont_form_back {
                padding: 20px 20px 60px;
            }
        }
    }
}

section.cont_form.conf_box{
    form {
        .cont_form_back {
            .cont_form_inner {
                ul {
                  padding-top: 23px;
                  .cont_th {
                      font-weight: bold;
                  }
                  .cont_input {
                      width: 73.5969387755%;
                  }
                }
                .cont_ul_textarea {
                    .cont_textarea {
                        height: auto;
                    }
                }
            }
        }

        .cont_bottom {
            .cont_inner {
                .cont_submit {
                    display: flex;
                    margin-top: 63px;
                    justify-content: space-around;

                    input[type="submit"] {
                        width: 48%;
                        &:first-child {
                          background-color: #666666;
                          border-color: #666666;
                        }
                    }
                }
            }
        }
    }
    @media #{$size_800} {
        form {
            .cont_form_back {
                padding: 40px 20px 60px;
                .cont_form_inner {
                  ul {
                    .cont_input{
                      height: 25px;
                    }
                  }
                }
            }
            .cont_bottom {
                padding: 60px 20px;
            }
        }
    }
    @media #{$size_700} {
        form {
          .cont_form_back {
              .cont_form_inner {
                  ul {
                    .cont_input {
                        width: 100%;
                    }
                  }
              }
          }
            .cont_bottom {
                .cont_inner {
                    ul {
                        .cont_input {
                            width: 100%;
                        }
                    }
                    .cont_submit {
                        margin-top: 0;
                        max-width: inherit;
                    }
                }
            }
        }
    }
}

section.cont_pb200 {
    padding: 60px 20px 400px;
    @media #{$size_600} {
        padding: 20px 20px 100px;
    }
    @media #{$size_400} {
        padding: 0 20px 80px;
    }
}
